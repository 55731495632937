<mat-icon class="help-icon" #helpIcon>help</mat-icon>
<h1>Export</h1>
<app-help-box key="export" [helpIcon]="helpIcon._elementRef">
    <p>
        Hier können die Rohdaten sowie Beschreibungen heruntergeladen werden.
    </p>
    <h2 class="center">Achtung</h2>
    <p>
        Diese Funktion darf nur durch den Support von IO-Dynamics genutzt werden. Bei Fehlerhafter benutzung wird eine
        große Last auf dem Server erzeugt, was zu Ausfällen der Instanz führen kann!
    </p>
    <p>
        Diese API unterscheidet im Verhalten zwischen Beschreibungen und Daten.
    </p>
    <ul>
        <li>
            Die Daten (Name ende auf _DATA)<br>
            Hier wird Serverseitig nur wenig cache benutzt, und die Daten ziemlich direkt von der Datenbank an den
            Brower gesendet. Wenn die Internet-Anbindung beim herunterladen sehr schnell ist, kann dass eine noch
            höhere Netzwerk-Last beim Backend bedeuten, da das Backend gleichzeitig mit DB und Browser kommuniziert.
            Hier bitte darauf achten, das V-LAN des Backends nicht zu überlasten, um Einflüße auf andere Services
            zu vermeiden. Die CPU Last im Backend durch den Export wird maximal ein CPU-Kern sein, daher ist hier
            wenig Gefahr, für Probleme.<br>
            Es ist möglich diese API mit den bekannten <code>&name=...</code> sowie <code>&pass=...</code> zu benutzen,
            um einen Download mit einem externen Tool (z.B. wget) zu starten.
            Hier ein Beispiel, um wget mit speed-limit zu benutzen (5M = ca. 50MBit/s):
            <pre appCode="BASH">$ wget --limit-rate=5M https://xxx.io-elon.de/api/export?name=xxx&pass=xxx&type=xxx_DATA&cols=xxx.xxx.xxx.xxx</pre>
        </li>
        <li>
            Beschreibungen (Name endet auf _DESCRIPTION)<br>
            Die Beschreibungen sind in der Regel wenige MB und die Notwendigen Daten werden im Backend durch Hibernate
            gecached, hier sind keine Probleme zu erwarten.
        </li>
    </ul>
    <h2 class="center">Dateigröße</h2>
    <p>
        Wie groß der Export am ende genau wird, lässt sich vorher nicht genau sagen. Um ein Gefühl zu entwickeln hier
        einmal das Beispiel meiner Test-Datenbank:<br>
        Mit folgendem SQL Befehl kann man die größe der Daten in der Datenbank ermitteln:<br>
        Bitte beachten, bei :dbName den Namen der Datenbank einzutragen. Bei mir <code>'io_elon_v2_jens</code>
    </p>
    <pre appCode="SQL">SELECT
    pi.inhparent::regclass AS parent_table_name,
    pg_size_pretty(sum(pg_total_relation_size(psu.relid))) AS total,
    pg_size_pretty(sum(pg_relation_size(psu.relid))) AS internal,
    pg_size_pretty(sum(pg_table_size(psu.relid) - pg_relation_size(psu.relid))) AS external, -- toast
    pg_size_pretty(sum(pg_indexes_size(psu.relid))) AS indexes
FROM pg_catalog.pg_statio_user_tables psu
JOIN pg_class pc ON psu.relname = pc.relname
JOIN pg_database pd ON pc.relowner = pd.datdba
JOIN pg_inherits pi ON pi.inhrelid = pc.oid
WHERE pd.datname = :dbName AND pi.inhparent::regclass::varchar LIKE '%_data_rows'
GROUP BY pi.inhparent
ORDER BY sum(pg_total_relation_size(psu.relid)) DESC;</pre>

</app-help-box>
<div class="d-flex flex-column">
    @for (e of exportOptions | keyvalue; track e.key) {
        <h3>{{e.key}}</h3>
        <div>
            <div class="d-flex flex-row justify-content-start w-100 align-items-end">
                <h4 class="flex-grow-1">Select fields:</h4>
                <button mat-button mat-flat-button color="primary" class="m-2" (click)="all(e)">Alle</button>
                <button mat-button mat-flat-button color="primary" class="m-2" (click)="none(e)">Keine</button>
            </div>
            <div class="d-flex flex-row flex-wrap">
                @for (o of e.value; track o.name){
                    <mat-checkbox [(ngModel)]="o.selected">{{o.name}}</mat-checkbox>
                }
            </div>
        </div>

        <h4>Download:</h4>
        @if(buildLink(e); as link) {
            <pre>{{link}}</pre>
            <a class="download-link" [download]="instanceName + '_' + e.key + '.csv'" [href]="link">Download im Browser</a>
        }
        <hr *ngIf="!$last">
    }
</div>
