import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

interface Line {
  key: string;
  value: string | IComplexLiveValue;
  reference?: number;
}

interface Caption {
    text: string;
    link?: string;
}

export interface IComplexLiveValue {
    value: string,
    showAgeIcon: boolean,
    age: string
}

export type Keys<T> = Array<{ display: string, field: keyof T | string, formatter: (val: any) => (string | IComplexLiveValue), caption?: Caption }>;

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent<T> implements OnInit, OnChanges {
  @Input() obj!: T;
  @Input() keys!: Keys<T>;
  @Input() headline!: string;

  displayedColumns: string[] = ['key', 'value'];

  dataSource = new MatTableDataSource<Line>([]);
  selection = new SelectionModel<Line>(true, []);

  public lines!: Line[];
  public captions!: Caption[];

  constructor() {
  }

  ngOnInit(): void {
      let capi = 1;
      this.lines = this.keys.map(key => {
        const path = (key.field as string).split(".");
        const rawValue = path.reduce((previousValue: any, currentValue) => previousValue[currentValue], this.obj);
        const formatted = key.formatter(rawValue);
        const ref = key.caption?  capi++: undefined;
        return {key: key.display, value: formatted, reference: ref};
      });

      // @ts-ignore
      this.captions = this.keys.filter(key=> key.caption!==undefined)
                                .map(key => key.caption);

      this.dataSource = new MatTableDataSource<Line>(this.lines);
  }

    ngOnChanges(changes: SimpleChanges): void {
        let capi = 1;
        this.lines = this.keys.map(key=> {
            const path = (key.field as string).split(".");
            const rawValue = path.reduce((previousValue: any, currentValue) => previousValue[currentValue], this.obj);
            const formatted = key.formatter(rawValue);
            const ref = key.caption?  capi++: undefined;
            return {key: key.display, value: formatted, reference: ref};
        });

        // @ts-ignore
        this.captions = this.keys.filter(key=> key.caption!==undefined)
            .map(key => key.caption);

        this.dataSource.data = this.lines;
    }

    isSimple(val: any) {
      return typeof val === "string";
    }
}
