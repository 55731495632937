import { Component, OnInit, OnDestroy } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {
    Evse, EvseAction, EvseActionArgument, EvseActionResult, Rfid,
    SystemInfoEvseType,
    SystemInfoSupportedEvseActionArguments
} from '@io-elon-common/frontend-api';
import {EvseService} from "../../service/evse.service";
import {SystemInfoSupportedEvseActions} from "@io-elon-common/frontend-api/lib/model/systemInfoSupportedEvseActions";
import {AuthService} from "../../../../shared/guards/auth.service";
import {SystemService} from "../../../../services/api-handlers/system.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from 'rxjs/operators';
import {RfidService} from '../../../rfid/service/rfid.service';

class BulkAction {
    public evseId: number;
    public evseName: string|undefined;
    public state: "pending"|"running"|"failed"|"done" = "pending";
    public result: string = "";
    constructor(evseId: number, evseName: string|undefined) {
        this.evseId = evseId;
        this.evseName = evseName;
    }
}

@Component({
    selector: 'app-evse-bulk-operations-view',
    templateUrl: './evse-bulk-operations-view.component.html',
    styleUrls: ['./evse-bulk-operations-view.component.scss'],
})
export class EvseBulkOperationsViewComponent implements OnInit, OnDestroy {
    public evses: Evse[] = [];
    public actions: SystemInfoSupportedEvseActions[] = [];
    public actionSupportCount = new Map<string, number>();
    public selectedAction: SystemInfoSupportedEvseActions | undefined;
    public argumentsValues: { [key: string]: string; } = {};
    public argumentType = SystemInfoSupportedEvseActionArguments.ArgumentTypeEnum;
    public selectableEvses: Evse[] = [];
    public selectedEvseIds: number[] = [];
    public actionStates: BulkAction[] = [];
    public maxParallelActions = 5;
    public actionArgumentCache: Array<EvseActionArgument> = [];
    public filterdRfids!: Observable<Rfid[] | undefined>;
    public rfidControl = new FormControl('');
    public removeEvseSelection: Subject<boolean> = new Subject();

    private systemInfo: SystemInfoEvseType[] | undefined = [];
    private evseSubscription!: Subscription;
    private isEvseSelectionChanged: boolean = false;
    private rfids!: Rfid[];
    constructor(
        private readonly evseService: EvseService,
        private readonly authService: AuthService,
        private readonly systemService: SystemService,
        private  rfidService: RfidService
    ) { }
    async ngOnInit(): Promise<void> {
        this.systemService.getSystemInfo().then(info => {
            this.systemInfo = info.supportedEvses;
        })
        this.evseSubscription = this.evseService.getAll().subscribe(list => {
            if (list === undefined) {
                return;
            }
            this.actionSupportCount.clear();
            this.evses = list;
            this.evses.sort((a, b) => a.name.localeCompare(b.name));
            this.evses.forEach((evse) => {
                this.systemService.getActorActions(evse).forEach(action => {
                    if (!this.actions.some(x => x.name == action.name)) {
                        this.actions.push(action);
                    }
                    const supportCount = this.actionSupportCount.get(action.name);
                    if (!supportCount) {
                        this.actionSupportCount.set(action.name, 1);
                    } else {
                        this.actionSupportCount.set(action.name, supportCount + 1);
                    }
                })
            });
            this.updateSelectableEvses(false, false);
        });
        this.rfids = await this.rfidService.getAllPromise();
        this.filterdRfids = this.rfidControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                if (typeof value === 'string') return this.filterRfids(value);
                return this.rfids.slice();
            })
        );
    }

    public get isDev(): boolean {
        return this.authService.isDeveloper();
    }

    ngOnDestroy() {
        this.evseSubscription.unsubscribe();
    }

    protected readonly console = console;

    evseSelectionChanged(data: number[]) {
        this.selectedEvseIds = data;
        this.isEvseSelectionChanged = true;
    }

    updateSelectableEvses(deselectEvses: boolean, clearActionArgumentCache: boolean) {
        this.selectableEvses = [];
        this.evses.forEach(e => {
            if (this.systemService.getActorActions(e).some(a => a.name === this.selectedAction?.name)) {
                this.selectableEvses.push(e);
            }
        });
        this.removeEvseSelection.next(deselectEvses);
        if (clearActionArgumentCache) {
            this.actionArgumentCache = [];
        }
    }

    public startBulkAction() {
        this.actionStates.forEach(s => {
            if (s.state == "failed") {
                s.state = "pending";
            }
        });
        if (!this.selectedAction) {
            this.failAllActions("No Action selected");
            return;
        }
        for (const argument of this.selectedAction.arguments) {
            if (this.checkValidation(this.argumentsValues[argument.name], argument.validationPattern)) {
                this.actionArgumentCache.push({
                    name: argument.name,
                    value: this.argumentsValues[argument.name]
                });
            } else {
                this.failAllActions("Invalid Argument Value for: "+argument.displayName);
                return;
            }
        }
        this.triggerNextAction();
    }

    public failAllActions(reason: string) {
        this.actionStates.forEach((s,i) => {
            this.actionStates[i].state = "failed"
            this.actionStates[i].result = reason;
        })
    }

    public onStepChange(event: any): void {
        if (event.selectedIndex === 2 && this.isEvseSelectionChanged) {
            this.actionStates = [];
            this.selectedEvseIds.forEach(evseId => this.actionStates.push(new BulkAction(evseId, this.selectableEvses.find(x => x.id === evseId)?.name)));
            this.isEvseSelectionChanged = false;
        }
    }

    public updateActionSelection($event: SystemInfoSupportedEvseActions) {
        this.selectedAction = $event;
        this.updateSelectableEvses(true, true)
    }

    private actionDone(actionIndex: number, result: EvseActionResult) {
        if (result.success) {
            this.actionStates[actionIndex].state = "done";
        } else {
            this.actionStates[actionIndex].state = "failed";
        }
        this.actionStates[actionIndex].result = result.result;
        this.triggerNextAction();
    }

    private triggerNextAction() {
        let freeCount = this.maxParallelActions - this.actionStates.filter(x => x.state === "running").length;
        if (freeCount > 0) {
            this.actionStates.forEach((s, i) => {
                if (s.state == "pending" && freeCount > 0) {
                    this.startAction(i);
                    freeCount--;
                }
            })
        }
    }

    private startAction(actionIndex: number) {
        if (!this.selectedAction) {
            return;
        }
        this.actionStates[actionIndex].state = "running";
        const evseId = this.actionStates[actionIndex].evseId;
        const evseAction: EvseAction = {name: this.selectedAction.name, arguments: this.actionArgumentCache};

        this.evseService.executeAction(evseId, evseAction).then(r => {
            this.actionDone(actionIndex, r);
        });
    }

    private checkValidation(value: string, pattern: string): boolean {
        if (value === "") {
            return false;
        }
        const regex = new RegExp(pattern);
        return regex.test(value);
    }

    private filterRfids(search: string): Rfid[] {
        const filter = search.toLowerCase();

        return this
        .rfids
        .filter(rfid => rfid.canView)
        .filter(rfid => rfid.name.toLowerCase().includes(filter)
            || rfid.token.includes(filter)
            || rfid.id.toString().startsWith(filter));

    }
}
