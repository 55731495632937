import {Injectable} from '@angular/core';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {
    ExecuteTeslaAccountAddOrUpdate,
    TeslaAccount,
    TeslaAccountVehicle,
    TeslaAccountVehicleList
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {BehaviorSubject} from 'rxjs';
import {
    EditTeslaAccountDialogComponent
} from '../dialogs/edit-tesla-account-dialog/edit-tesla-account-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
  providedIn: 'root'
})
export class TeslaAccountService extends DialogHandler<TeslaAccount, ExecuteTeslaAccountAddOrUpdate, ExecuteTeslaAccountAddOrUpdate, {}, void, void> {
    private vehicleListCache: CacheUpdater<TeslaAccountVehicle[], number>;

    public constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'TeslaAccount', toastr, dialog, dialogService, POLL_INTERVALS.teslaAccounts);
        this.vehicleListCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    public getVehicleList(id: number, showAlerts = true): BehaviorSubject<TeslaAccountVehicle[] | undefined> {
        return this.vehicleListCache.getOrCreateGet(id, ()=>this.apiService.getTeslaAccountVehicleList(showAlerts, id, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list)).data;
    }

    public async getVehicleLists(showAlerts = true): Promise<{ [key: string]: TeslaAccountVehicleList }> {
        return await this.apiService.getTeslaAccountVehicleLists(showAlerts, undefined, undefined, ApiHandler.customerId).toPromise() as any
    }

    public async updateVehicleList(id: number, showAlerts = true): Promise<void> {
        await this.apiService.updateTeslaVehicleList(showAlerts, id, undefined, undefined, ApiHandler.customerId, {}).toPromise();
    }

    public async updateTokenNow(id: number, showAlerts = true): Promise<void> {
        await this.apiService.updateTeslaToken(showAlerts, id, undefined, undefined, ApiHandler.customerId, {});
    }

    protected getEditConfig(teslaAccount: TeslaAccount): TDialogOptions<ExecuteTeslaAccountAddOrUpdate, EditTeslaAccountDialogComponent> {
        return {
            headline: "Tesla Account bearbeiten",
            component: EditTeslaAccountDialogComponent,
            executeCallback: editResult => this.update(teslaAccount.id, editResult),
            editElement: {
                name: teslaAccount.name,
                ownerApiToken: teslaAccount.ownerApiToken,
                oAuthRefreshToken: teslaAccount.oAuthRefreshToken
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteTeslaAccountAddOrUpdate, EditTeslaAccountDialogComponent> {
        return {
            headline: "Tesla Account anlegen",
            component: EditTeslaAccountDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "Tesla Account",
                oAuthRefreshToken: "",
                ownerApiToken: ""
            }
        };
    }
}
