<h1 mat-dialog-title>Ladevorgang</h1>

<div mat-dialog-content>
    <table>
        <tr>
            <td></td>
            <th>Zeit</th>
            <th>Energie¹</th>
            <th>Berechnete Energie²</th>
            <th>Reservierte Energie³</th>
            <th>Kostenstelle</th>
            <td>{{data.kostenstelle || ""}}</td>
        </tr>
        <tr>
            <th>Start</th>
            <td>{{data.tstStart | date:'short'}}</td>
            <td>{{data.energyStart !== undefined ? (data.energyStart / 1000).toFixed(3) : "--"}} kWh</td>
            <td>{{data.energyStartMath !== undefined ? (data.energyStartMath / 1000).toFixed(3) : "--"}} kWh</td>
            <td>{{(data.iCpEnergyStart / 1000).toFixed(3)}} kWh</td>
            <th>Fahrzeug:</th>
            <td>
                <ng-container *ngIf="data.vehicleId !== undefined; else guest">
                    <app-vehicle-link [vehicleId]="data.vehicleId"></app-vehicle-link>
                </ng-container>
                <ng-template #guest>
                    Fremdfahrzeug
                </ng-template>
            </td>
        </tr>
        <tr>
            <th>Ende</th>
            <td>{{data.tstEnd ? (data.tstEnd | date:'short') : "--"}}</td>
            <td>{{data.energyEnd ? (data.energyEnd / 1000).toFixed(3) : "--"}} kWh</td>
            <td>{{data.energyEndMath ? (data.energyEndMath / 1000).toFixed(3) : "--"}} kWh</td>
            <td>{{data.iCpEnergyEnd ? (data.iCpEnergyEnd / 1000).toFixed(3) : "--"}} kWh</td>
            <th>Ladepunkt:</th>
            <td>
                <ng-container *ngIf="data.evseId != -1; else deleted">
                    <app-evse-link [evseId]="data.evseId"></app-evse-link>
                </ng-container>
                <ng-template #deleted>
                    Gelöschter Ladepunkt
                </ng-template>
            </td>
        </tr>
        <tr>
            <th></th>
            <td class="sum">{{data.tstEnd ? ((data.tstEnd - data.tstStart) | duration) : "Ladevorgang noch aktiv"}}</td>
            <td class="sum">{{data.energy ? (data.energy / 1000).toFixed(3) : "--"}} kWh</td>
            <td class="sum">{{data.energyMath ? (data.energyMath / 1000).toFixed(3) : "--"}} kWh</td>
            <td class="sum">{{(data.iCpEnergy / 1000).toFixed(3)}} kWh</td>
            <th class="rfid">RFID:</th>
            <td class="rfid"><pre style="display: inline">{{data.rfid || "--"}}</pre></td>
        </tr>
    </table>

    <hr>
    <hr>
    <h2>Aufschlüsselung der Ladephasen</h2>
    <table>
        <tr>
            <td></td>
            <th>Zeit</th>
            <th>Energie¹</th>
            <th>Berechnete Energie²</th>
            <th>Reservierte Energie³</th>
        </tr>
        <ng-container *ngFor="let t of data.transfers">
            <tr>
                <th>Start</th>
                <td>{{t.tstStart | date:'short'}}</td>
                <td>{{(t.energyStart !== undefined) ? (t.energyStart / 1000).toFixed(3) : "--"}} kWh</td>
                <td>{{(t.energyStartMath !== undefined) ? (t.energyStartMath / 1000).toFixed(3) : "--"}} kWh</td>
                <td>{{(t.iCpEnergyStart / 1000).toFixed(3)}} kWh</td>
            </tr>
            <tr>
                <th>Ende</th>
                <td>{{t.tstEnd ? (t.tstEnd | date:'short') : "--"}}</td>
                <td>{{t.energyEnd ? (t.energyEnd / 1000).toFixed(3) : "--"}} kWh</td>
                <td>{{t.energyEndMath ? (t.energyEndMath / 1000).toFixed(3) : "--"}} kWh</td>
                <td>{{t.iCpEnergyEnd ? (t.iCpEnergyEnd / 1000).toFixed(3) : "--"}} kWh</td>
            </tr>
            <tr>
                <th></th>
                <td class="sum">{{t.tstEnd ? ((t.tstEnd - t.tstStart) | duration) : "Ladevorgang noch aktiv"}}</td>
                <td class="sum">{{t.energyEnd ? ((t.energyEnd - t.energyStart) / 1000).toFixed(3) : "--"}} kWh</td>
                <td class="sum">{{t.energyEndMath ? ((t.energyEndMath - t.energyStartMath) / 1000).toFixed(3) : "--"}} kWh</td>
                <td class="sum">{{t.iCpEnergyEnd ? ((t.iCpEnergyEnd - t.iCpEnergyStart) / 1000).toFixed(3) : "--"}} kWh</td>
            </tr>
        </ng-container>
    </table>
    <hr/>
    <ng-container *ngIf="showMissingTimeTxt" >
        <span>
            Für die letzten {{missingTimeInMinutes}} min ({{missingTimeInPercentage}}%) des Ladevorgangs sind keine Messdaten vom Ladepunkt gemeldet worden. Ladevorgang möglicherweise nicht vollständig Eichrechtskonform.
        </span>
        <hr/>
    </ng-container>
    <mat-hint><!--Do not add a space here!
     -->1: Messungen wurden von einem in dem Ladepunkt verbauten Energiezähler durchgeführt. Nur für Ladepunkte mit OCPP verfügbar<br>
        2: Werte basieren auf von dem Ladepunkt gemeldeten Storm- und Leistungsmessungen. Diese Zahlen sollten eine gute Annäherung sein, sind aber nicht immer zuverlässig<br>
        3: Werte basieren auf den von IO-ELON vorgegebenen Ladeplänen. Es wurde keine Messung durchgeführt. Diese Zahlen weichen oft sehr stark vom dem realen Verbrauch ab.
    </mat-hint>
</div>

<div mat-dialog-actions>
    <button mat-button  color="primary" (click)="dialogRef.close()">Schließen</button>
</div>
