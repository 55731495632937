import {Component, Inject, OnInit} from '@angular/core';
import {Evse, EvseValidationResult, WhitelistEntry} from "@io-elon-common/frontend-api";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {WhitelistService} from "../../../whitelist/service/whitelist.service";
import {EvseService} from "../../service/evse.service";
import {BehaviorSubject} from "rxjs";
import {ActorDetailsDialogComponent} from "../actor-details-dialog/actor-details-dialog.component";
import FixMethodEnum = EvseValidationResult.FixMethodEnum;
import ValidationResultEnum = EvseValidationResult.ValidationResultEnum;
import {AuthService} from '../../../../shared/guards/auth.service';

@Component({
    selector: 'app-evse-validation-handling-dialog',
    templateUrl: './evse-validation-handling-dialog.component.html',
    styleUrls: ['./evse-validation-handling-dialog.component.scss']
})
export class EvseValidationHandlingDialogComponent implements OnInit {
    public readonly FixMethodEnum = FixMethodEnum;
    public evseId: number;
    public evse?: BehaviorSubject<Evse | undefined>;
    public whitelist?: BehaviorSubject<WhitelistEntry[] | undefined>
    public loadingActorDetails = false;
    public canEditWhiteList: boolean = false;


    public constructor(
        private dialogRef: MatDialogRef<EvseValidationHandlingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly dialogData: {
            evseId: number,
        },
        private readonly toastr: ToastrService,
        private readonly whitelistService: WhitelistService,
        private readonly evseService: EvseService,
        private readonly dialog: MatDialog,
        private readonly authService: AuthService
    ) {
        this.evseId = dialogData.evseId;
    }

    public ngOnInit(): void {
        this.dialogRef.updateSize('80%')
        this.whitelist = this.whitelistService.getAll();
        this.evse = this.evseService.get(this.evseId);
        if (this.authService.hasGlobalPermission("VIEW_EVSE_VALIDATION_WHITELIST") &&
            this.authService.hasGlobalPermission("ADD_EVSE_VALIDATION_WHITELIST") &&
            this.authService.hasGlobalPermission("DELETE_EVSE_VALIDATION_WHITELIST")) {
            this.canEditWhiteList = true;
        }
    }

    public getKey(idx: number, val: EvseValidationResult): string {
        return val.ruleKey;
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public async whitelistAdd(rule: EvseValidationResult) {
        try {
            await this.whitelistService.create({
                evse: this.evseId,
                key: rule.ruleKey
            });
            this.toastr.success("Meldung wird ignoriert")
        } catch (e) {
            console.log(e)
            this.toastr.warning("Fehler beim erstellen des Whitelist Eintrags.")
        }
    }

    public async whitelistRemove(whitelist: WhitelistEntry[], val: EvseValidationResult) {
        const entry = whitelist.find(e => e.key === val.ruleKey && e.evse === this.evseId);
        if(entry) {
            try {
                await this.whitelistService.delete(entry.id, {});
                this.toastr.success("Meldung wird nicht mehr ignoriert.")
            } catch (e) {
                console.log(e)
                this.toastr.warning("Fehler beim löschen.")
            }
        } else {
            this.toastr.warning("Eintrag wurde nicht auf der Whitelist gefunden.")
        }
    }

    public async setDefault(rule: EvseValidationResult) {
        try {
            const ret = await this.evseService.executeAction(this.evseId, {
                name: "setSpecificData",
                arguments: [{
                    name: "key",
                    value: rule.ruleKey
                }, {
                    name: "value",
                    value: rule.ruleDefaultVal
                }]
            });
            if(ret.success) {
                this.toastr.success("Konfiguration wurde geschrieben. <br> Bitte klicken Sie auf 'Erneut validieren', um die Änderungen anzuzeigen")
            } else {
                this.toastr.warning(ret.result, "Fehler")
            }
        } catch (e) {
            console.log(e);
            this.toastr.warning("Fehler beim setzen des Wertes.")
        }
    }

    validationResultToDisplayName(result: ValidationResultEnum): string {
        switch (result) {
            case "VALID": return "Wert OK";
            case "INVALID": return "Wert Falsch";
            case "VALIDATION_ERROR": return "Wert konnte nicht gelesen werden";
            case "WHITELIST_VALID":
            case "WHITELIST_INVALID":
            case "WHITELIST_ERROR": return "Ignoriert";
        }
    }

    public isOnWhitelist(whitelist: WhitelistEntry[], val: EvseValidationResult) {
        return whitelist.some(e => e.key === val.ruleKey && e.evse === this.evseId);
    }

    public async showDetails() {
        this.loadingActorDetails = true;
        try {
            const evse = this.evse?.getValue();
            if (evse === undefined) {
                this.toastr.warning("Aktion nicht möglich");
                return;
            }
            this.dialog.open(ActorDetailsDialogComponent, {
                data: {
                    evse: evse
                },
                maxHeight: '90vh',
                width: '80vw',
                minHeight: '90vh',
            });
            this.close();
        } finally {
            this.loadingActorDetails = false;
        }
    }

    public async revalidate() {
        try {
            this.loadingActorDetails = true;
            const ret = await this.evseService.executeAction(this.evseId, {
                name: "validateConfiguration",
                arguments: []
            });
            if (ret.success) {
                this.toastr.success("Die Konfiguration wurde erneut validiert.")
            } else {
                this.toastr.warning(ret.result, "Fehler")
            }
        } catch (e) {
            console.log(e);
            this.toastr.warning("Fehler beim setzen des Wertes.")
        } finally {
            this.loadingActorDetails = false;
        }
    }

    public getRemarkText(val: EvseValidationResult): string {
        switch (val.validationResult) {
            case "WHITELIST_VALID":
            case "WHITELIST_INVALID":
            case "WHITELIST_ERROR": return "Whitelist - " + val.validationMsg;
            default: return val.validationMsg;
        }
    }
}
