import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {EventLog, EventLogEntry} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FormControl, FormGroup } from '@angular/forms';
import {Moment} from 'moment';
import {AuthService} from '../../../guards/auth.service';
import {localStorageGet, localStorageSave} from '../../../helper/typed-local-storage';
import * as moment from 'moment';

@Component({
    selector: 'app-event-log-table',
    templateUrl: './event-log-table.component.html',
    styleUrls: ['./event-log-table.component.scss']
})
export class EventLogTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    loading = true;
    events!: EventLogEntry[];
    start: Moment = moment().subtract(7, "days");
    end: Moment = moment();
    level = +localStorageGet('EVENT_LOG_LEVEL', "400");
    isDev!: boolean

    @Input() loadDataCb!: (start: number, end: number) => Promise<EventLog>;

    range = new FormGroup({
        start: new FormControl(this.start),
        end: new FormControl(this.end)
    });

    displayedColumns: string[] = ['tst', 'level', 'message'];

    today = moment();

    dataSource = new MatTableDataSource<EventLogEntry>([]);
    selection = new SelectionModel<EventLogEntry>(true, []);
    selectedIndex = 0;

    constructor(
        private readonly authService: AuthService) {
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();
        this.dataSource = new MatTableDataSource<EventLogEntry>(this.events);
        this.dataSource.paginator = this.paginator;

        this.range.setValue({
            start: this.start,
            end: this.end
        });

        this.refresh();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    selectRow(row: EventLogEntry) {
    }

    async refresh() {
        this.loading = true;
        const start = this.range.get("start")?.value as Moment;
        let end = this.range.get("end")?.value as Moment|null;
        if (end === null) end = start;
        this.events = (await this.loadDataCb(
            start.clone().startOf('day').valueOf(),
            end.clone().endOf('day').valueOf())).events;

        localStorageSave('EVENT_LOG_LEVEL', this.level + "" as any)
        this.events = this.events
            .filter(e => e.level <= this.level)
            .sort((e1, e2) => {
                return e2.tst - e1.tst;
            });
        this.dataSource = new MatTableDataSource<EventLogEntry>(this.events);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
    }
}
