<div class="d-flex flex-column" style="height: 600px;">
    <div class="d-flex justify-content-start align-items-center p-3 gap-2 flex-grow-0">
        <h1 class="flex-grow-1 p-0 m-0">Diagnose Uploads</h1>
        <mat-icon class="help-icon flex-grow-0 clickable" #helpIcon>help</mat-icon>
        <mat-icon class="flex-grow-0 clickable" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <app-help-box [helpIcon]="helpIcon._elementRef" key="LOG_UPLOAD_DIALOG" style="max-width: 700px" class="flex-grow-0">
        <p>
            Hier wird eine Übersicht aller Uploads angezeigt, die für diesem Ladepunkt erstellt wurden.
            Die Uploads enthalten Diagnosedaten zur Analyse von Problemen der Station. Diese Daten werden
            oft vom Hersteller der Ladestation angefordert, wenn ein Problem gemeldet wird.
        </p>
        <p>
            Sie können diese Daten beliebig oft von der Station anfordern. Es wird empfohlen nach dem
            Anfordern ca. 10 bis 30 Minuten zu warten, da es je nach Station längere Zeit dauern kann,
            bis der Upload abgeschlossen ist. Je nach Station ist es möglich, dass der Fortschritt
            im Protokoll der Ladestation beobachtet werden kann, wenn entsprechende Daten gesendet werden.
        </p>
    </app-help-box>
    @if (dialogData | async; as dialogData) {
        <div class="d-flex flex-row gap-2 p-3 flex-grow-1">
            <div style="overflow-y: auto">
                <mat-selection-list #uploads [multiple]="false">
                    <mat-list-option [value]="''">Neu</mat-list-option>
                    @for (u of dialogData.logUploads.uploads; track u.uploadId) {
                        <mat-list-option [value]="u.uploadId">{{ u.create | date:'short' }}</mat-list-option>
                    }
                </mat-selection-list>
            </div>

            <div>
                @if (uploads.selectedOptions.hasValue() && uploads.selectedOptions.selected[0].value; as uuid) {
                    <!-- Selection available -->
                    <iframe [src]="url(uuid)" width="400"
                            height="600"></iframe>
                    <a [href]="url(uuid)">Teilen</a>
                } @else {
                    <!-- New -->
                    <div style="width: 400px;"
                         class="d-flex flex-column align-items-start gap-2">
                        <p>
                            Hier können die Diagnosedaten von der Station angefordert werden:
                        </p>
                        <button class="align-self-center" mat-raised-button color="primary" (click)="requestLogs()">
                            Diagnosedaten anfordern
                        </button>
                        <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>
                        <div *ngIf="requestResult" [innerHTML]="requestResult"></div>
                    </div>
                }
            </div>
        </div>
    }
</div>
