import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
    Basis,
    ChargePlan,
    ChargePlanValue,
    ChargingSessionList, DataValueString,
    Evse,
    NotificationEntry, Vehicle
} from '@io-elon-common/frontend-api';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationService } from '../../../../services/api-handlers/notification.service';
import { AgePipe } from '../../../../shared/helper/age.pipe';
import { EvseService } from '../../service/evse.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractTableComponent } from '../../../../shared/components/tables/AbstractTableComponent';
import { PowerSupplyService } from '../../../basis/service/power-supply.service';
import { SystemService } from "../../../../services/api-handlers/system.service";
import { MatPaginator } from '@angular/material/paginator';
import {localStorageGet, localStorageSave} from "../../../../shared/helper/typed-local-storage";
import {
    EvseValidationHandlingDialogComponent
} from "../../dialogs/evse-validation-handling-dialog/evse-validation-handling-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LivedataService} from "../../../../services/api-handlers/livedata.service";
import {AuthService} from "../../../../shared/guards/auth.service";
import {DatePipe} from '@angular/common';
import {ChargingSession} from '@io-elon-common/frontend-api/lib/model/chargingSession';
import {Subject} from 'rxjs';
import {EvseHealthDataUtils} from '../../../../shared/helper/evse-health-data-utils';

export interface ChargingStatusInterface {
    evseId: number,
    chargingStatus: ChargingSession.StateEnum,
    isLoading: boolean
}
@Component({
    selector: 'app-evse-table',
    templateUrl: './evse-table.component.html',
    styleUrls: ['./evse-table.component.scss']
})
export class EvseTableComponent extends AbstractTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @Input() evses!: Evse[];
    @Input() chargePlan?: ChargePlan;
    @Input() basis!: Basis;
    @Input() isSelect: boolean = false;
    @Input() showMode: boolean = true;
    @Input() showAlgo: boolean = true;
    @Input() showVersions: boolean = false;
    @Input() showOcppArgs: boolean = false;
    @Input() removeEvseSelection: Subject<boolean> = new Subject();
    public activeNotifications: NotificationEntry[] = [];
    public mutedNotifications: NotificationEntry[] = [];
    public isDev = false;
    public chargingStatusInterfaces: ChargingStatusInterface[] = [];
    public showFilterBox: boolean = localStorageGet('SHOW_TABLE_FILTER_BOX', "false") === "true";
    public logSupportedEvses: Set<number> = new Set<number>();
    @Output() selectionChanged: EventEmitter<number[]> = new EventEmitter();

    get displayedColumns(): string[] {
        let columns: string[] = [];
        if (this.isSelect) {
            columns.push("select")
        }
        columns.push('icon');
        columns.push('name');
        columns.push('plug');
        columns.push('vehicle');
        columns.push('type');
        if (this.showVersions) {
            columns.push("versions")
        }
        if (this.showOcppArgs) {
            columns.push("ocpp")
        }
        columns.push('auth');
        if (this.showMode) {
            columns.push('mode');
        }
        if (this.showAlgo) {
            columns.push('status') /*'algoStatus'*/
        }
        columns.push("actions");
        return columns;
    }

    dataSource = new MatTableDataSource<Evse>([]);
    selection = new SelectionModel<number>(true, []);
    selectedIndex = 0;
    loading: boolean[] = [];

    @Input() vehicles!: Vehicle[];
    @Input() canCreate!: boolean;
    @Input() activeChargingSessions: ChargingSessionList = {list: [], price: 0};

    displayStopChargeButton: boolean = false;


    constructor(
        private readonly notificationService: NotificationService,
        private readonly evseService: EvseService,
        private readonly agePipe: AgePipe,
        private readonly toastrService: ToastrService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly systemService: SystemService,
        private readonly dialog: MatDialog,
        private readonly liveDataService: LivedataService,
        private readonly authService: AuthService,
        private datePipe: DatePipe,
        private readonly evseHealthDataUtils: EvseHealthDataUtils
    ) {
        super();
        this.selection.changed.subscribe(_ => this.selectionChanged.emit(this.selection.selected));
    }

    ngOnInit() {
        this.isDev = this.authService.isDeveloper();
        this.notificationService.getActiveNotifications().subscribe(x => {
            if (x == undefined)
                return;
            this.activeNotifications = x.activeNotifications.sort((n1, n2) => n1.id - n2.id);
            this.mutedNotifications = x.mutedNotifications.sort((n1, n2) => n1.id - n2.id);;
        });

        this.activeChargingSessions.list.filter(s => {
            this.chargingStatusInterfaces.push({evseId: s.evseId, chargingStatus: s.state, isLoading: false});
        });

        for (const evse of this.evses) {
            const supportsLog = this.evseService.isLogSupported(evse);
            if (supportsLog) {
                this.logSupportedEvses.add(evse.id);
            }
        }

        this.dataSource = new MatTableDataSource(this.evses);
        this.dataSource.paginator = this.paginator;

        this.displayStopChargeButton = this.systemService.getSystemInfoSync()?.displayStopChargeButton || false;

        this.dataSource.filterPredicate = (data: Evse, filter: string): boolean => {
            return JSON.stringify(data).includes(filter);
        }

        this.removeEvseSelection.subscribe(v => {
            if (v) {
                this.selection.clear();
            }
        });
    }

    ngOnChanges(): void {
        this.dataSource.data = this.evses
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    selectRow(row: Evse) {
        this.selectedIndex = row.id;
    }

    public showUnlockButton(evse: Evse): boolean {
        return !!evse.liveData.evsePlugged && evse.authEnabled
    }

    public async unlock(evse: Evse): Promise<void> {
        try {
            const response = await this.evseService.disableAuthTemp(evse.id);
            if(response.success) {
                this.toastrService.success("Ladepunkt wird freigegeben, bis das Auto abgesteckt wird.");
            } else {
                this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
                console.error(response.result);
            }
        } catch (e) {
            this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
        }
    }

    public isOutdated(evse: Evse): boolean {
        const watchdogTime = this.systemService.getWatchdogTime(evse.actorKey);
        return (evse.liveData.lastPackageTst ?? 0) < Date.now() - watchdogTime;
    }

    public getAge(evse: Evse): string {
        const connectTst = evse.liveData.lastConnectionTst ?? 0;
        const disconnectTst = evse.liveData.lastDisconnectionTst?? 0;
        const packetTst = evse.liveData.lastPackageTst?? 0;
        const events: DataValueString[] = evse.liveData.evseLastApiEvents?? [];

        if (connectTst == 0) { //Polling Actor
            if (this.isOutdated(evse)) {
                return "🔴 Letzte Daten: " + this.agePipe.transform(packetTst, undefined, true, false);
            } else {
                return "🟢 Aktuell"
            }
        } else {
            if (disconnectTst >= connectTst && packetTst < disconnectTst) {
                return "🔴 Offline (Seit "+this.agePipe.transform(disconnectTst, undefined, false)+ ")";
            } else {
                const bootNotificationTst = evse.liveData.evseLastBootnotification;
                const remoteType = evse.liveData.ocppRemoteType;

                const missingBootNotification = bootNotificationTst !== undefined && bootNotificationTst?.val < connectTst
                const restoredRemoteType = remoteType && (remoteType.tst >= connectTst)

                if (missingBootNotification && !restoredRemoteType) {
                    return "🚀Online (Verbindungsaufbau)";
                } else if(events.length>0) {
                    const event = events.reduce((acc, event)=> acc.tst>event.tst? acc: event);
                    if (event.tst>packetTst) {
                        switch (event.val) {
                            case "OCPP.Timeout":
                                return "🟡 Online (Antwortet nicht)";
                            case "OCPP.Rejected":
                                return "🟡 Online (Anfrage abgelehnt)";
                            default:
                                break;

                        }
                    }
                }

                if (packetTst > connectTst) {
                    if (this.isOutdated(evse)) {
                        return "🟡 Online (Letzte Daten " + this.agePipe.transform(packetTst, undefined, true, false) + ")";
                    } else {
                        return "🟢 Online (Daten aktuell)"
                    }
                } else {
                    return "🟡 Online ohne Daten (Seit "+this.agePipe.transform(connectTst, undefined, false)+")";
                }
            }
        }
    }

    public getAgeDetails(evse: Evse): string {
        const connectTst = evse.liveData.lastConnectionTst ?? 0;
        const disconnectTst = evse.liveData.lastDisconnectionTst ?? 0;
        const packetTst = evse.liveData.lastPackageTst ?? 0;
        let details = "Letzte Daten: "+this.agePipe.transform(packetTst, undefined, true, false);
        if (connectTst != 0) {
            details+="\nLetzter Verbindungsaufbau: "+this.agePipe.transform(connectTst, undefined, true, false);
        }
        if (disconnectTst != 0) {
            details+="\nLetzter Verbindungsabbruch: "+this.agePipe.transform(disconnectTst, undefined, true, false);
        }
        evse.liveData.evseLastApiEvents?.forEach((apiEvent: DataValueString) => {
            details+="\n"+apiEvent.val+": "+this.agePipe.transform(apiEvent.tst, undefined, true, false);
        });
        return details;
    }

    public async triggerSequence(evse: Evse) {
        try {
            const result = await this.evseService.triggerSequence(evse.id);
            if(result.success) {
                this.toastrService.success("Sequenz zur Fahrzeugerkennung wird gestartet.")
            } else {
                this.toastrService.error("Fehler beim starten der Sequenz.")
                console.error(result.result)
            }
        } catch (e) {
            this.toastrService.error("Fehler beim starten der Sequenz.")
        }
    }

    public async easeeLogin(evse: Evse): Promise<void> {
        await this.evseService.showEaseeLogin(evse);
    }

    public async edit(evse: Evse): Promise<void> {
        await this.evseService.showEditDialog(evse, await this.powerSupplyService.getAllPromise());
    }

    public async copy(evse: Evse) {
        await this.evseService.showCopyDialog(evse, await this.powerSupplyService.getAllPromise());
    }

    public async actions(evse: Evse) {
        await this.evseService.showActionDialog(evse);
    }

    public canStop(evse: Evse): boolean {
        return this.displayStopChargeButton && this.activeChargingSessions.list.filter(s => s.evseId === evse.id).length > 0
    }

    public isStopped(evse: Evse): boolean {
        const status = this.chargingStatusInterfaces.find(e => e.evseId === evse.id);
        return status !== undefined && status.chargingStatus === "stopped";
    }

    public async stop(evse: Evse): Promise<void> {
        const chargingSessions = this.activeChargingSessions.list.filter(s => s.evseId === evse.id);
        const evseId = chargingSessions[0].evseId;
        try {
            this.updateChargingStatus(evseId, chargingSessions[0].state, true);
            const response = await this.evseService.stopCharging(evseId);
            if(response.success) {
                this.updateChargingStatus(evseId, "stopped", false);
                this.toastrService.info("Ladevorgang wird gestoppt");
                return;
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim stoppen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim stoppen des Ladevorgangs");
            console.error(exc);
        }
        this.updateChargingStatus(evseId, chargingSessions[0].state, false);
    }

    public async continue(evse: Evse) {
        const chargingSessions = this.activeChargingSessions.list.filter(s => s.evseId === evse.id);
        const evseId = chargingSessions[0].evseId;
        try {
            this.updateChargingStatus(evseId, chargingSessions[0].state, true);
            const response = await this.evseService.continueCharging(evseId);
            if(response.success) {
                this.updateChargingStatus(evseId, "active", false);
                this.toastrService.info("Ladevorgang wird fortgesetzt.");
                return;
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs");
            console.error(exc);
        }
        this.updateChargingStatus(evseId, chargingSessions[0].state, false);
    }

    public async toggle(evse: Evse, field: "authEnabled" | "control" | "guestCharging" | "monitor" | "sequencesAllowed" | "smartCharging" | "slowChargeManagementEnabled" | "nonChargingCarsToMinAmps") {
        if (this.loading[evse.id]) {
            this.toastrService.warning("Es wird bereits eine Änderung für diesen Ladepunkt durchgeführt.")
            return;
        }
        if(field === 'nonChargingCarsToMinAmps' && !evse.slowChargeManagementEnabled) {
            this.toastrService.warning("Diese Funktion kann nur benutzt werden, wenn die erkennung für langsames Laden aktiv ist.")
            return;
        }
        evse[field] = !evse[field]
        try {
            this.loading[evse.id] = true;
            await this.evseService.update(evse.id, {
                actorArgs: evse.actorArgs,
                actorKey: evse.actorKey,
                authEnabled: evse.authEnabled,
                basisId: evse.basis.id,
                control: evse.control || false,
                guestCharging: evse.guestCharging || false,
                localId: evse.localId,
                maxI: evse.maxI,
                monitor: evse.monitor || false,
                name: evse.name,
                phaseRotation: evse.phaseRotation,
                powerSupplyId: evse.powerSupplyId,
                sequencesAllowed: evse.sequencesAllowed || false,
                smartCharging: evse.smartCharging || false,
                type: evse.type as string,
                minGuestI: evse.guestMinAmps,
                slowChargeManagementEnabled: evse.slowChargeManagementEnabled,
                nonChargingCarsToMinAmps: evse.nonChargingCarsToMinAmps,
                maintenance: evse.maintenanceEnabled,
                maintenanceReason: evse.maintenanceReason
            })
        } finally {
            delete this.loading[evse.id]
        }
    }

    public getPwrMsg(evse: Evse): {live: string, target: string, unit: string} | undefined {
        const target = evse.liveData.evseTargetI1?.val;

        const p1 = this.calcPwr(evse.liveData.evseP1?.val, evse.liveData.evseI1?.val, evse.liveData.evseU1?.val);
        const p2 = this.calcPwr(evse.liveData.evseP2?.val, evse.liveData.evseI2?.val, evse.liveData.evseU2?.val);
        const p3 = this.calcPwr(evse.liveData.evseP3?.val, evse.liveData.evseI3?.val, evse.liveData.evseU3?.val);

        if(p1 === undefined) {
            if (target === undefined) return undefined;
            return {
                live: "-",
                target: target + "A",
                unit: ""
            };
        }

        const p = p1 + (p2 || 0) + (p3 || 0);
        if (p === 0 && !target) return undefined;

        return {
            live: (p / 1000).toFixed(1),
            target: target? (target * 3 * 235 /1000).toFixed(1): "-",
            unit: "kW"
        }
    }

    private calcPwr(pwr: number | undefined, amps: number | undefined, voltage: number | undefined): number | undefined {
        if(pwr != undefined) {
            return pwr;
        }
        if(amps == undefined) {
            return undefined;
        }
        return amps * (voltage || 235);
    }

    public toggleTargetVisible() {
        localStorageSave("TARGET_VISIBLE", localStorageGet("TARGET_VISIBLE", "false") === "true" ? "false" : "true")
    }

    get targetVisible(): boolean {
        return localStorageGet("TARGET_VISIBLE", "false") === "true";
    }

    public getChargePlanInfo(row: Evse): ChargePlanValue | undefined {
        if(this.chargePlan) {
            const firstSlotPv = this.chargePlan.planValues.find(pv => pv.evse == row.id);
            if (!firstSlotPv) {
                return undefined;
            }
            if (firstSlotPv.t > Date.now()) {
                return firstSlotPv;
            }
            return this.chargePlan.planValues.filter(pv => pv.evse == row.id && pv.t < Date.now()).reverse()[0];
        }
        return undefined;
    }

    public getTooltip(pv: ChargePlanValue) {
        return pv.reasonDetails.join("\n")
    }
    public getIcon(reason: string) {
        return this.systemService.getSystemInfoSync()?.chargePlanReasons?.find(r => r.name == reason)?.icon;
    }
    public getText(reason: string) {
        return this.systemService.getSystemInfoSync()?.chargePlanReasons?.find(r => r.name == reason)?.text.replace("<br>", ' ');
    }

    public getValidations(evse: Evse) {
        if(evse.liveData.validationResult.some(v => v.validationResult === "INVALID" || v.validationResult === "VALIDATION_ERROR")) {
            return evse.liveData.validationResult;
        }
        return null;
    }

    public handleValidation(evse: Evse) {
        this.dialog.open(EvseValidationHandlingDialogComponent, {
            data: {
                evseId: evse.id,
                validations: evse.liveData.validationResult
            },
            maxHeight: '90vh'
        });
    }

    public async assign(veh?: Vehicle, evse?: Evse) {
        try {
            await this.liveDataService.assign(evse?.id, veh?.id);
            this.toastrService.success("Assignment erledigt.")
        } catch (e) {
            this.toastrService.error("Fehler beim Assignment")
        }
    }

    public async plugCycle(evse: Evse) {
        try {
            const before = evse.liveData.evsePlugged?.val;
            await this.liveDataService.setLiveData(evse?.id, undefined, "DataRow", [{
                key: "EVSE_PLUGGED",
                value: before ? "false" : "true"
            }], Date.now());
            await this.liveDataService.setLiveData(evse?.id, undefined, "DataRow", [{
                key: "EVSE_PLUGGED",
                value: before ? "true" : "false"
            }], Date.now());
            this.toastrService.success("Plug Cycle erledigt")
        } catch (e) {
            this.toastrService.error("Fehler beim Plug Cycle")
        }
    }

    public getNotificationTooltipText(notification: NotificationEntry): string {
        const tstDate = new Date(notification.tst);
        const recentTstDate = new Date(notification.recent_tst);
        const formattedTstDate = this.datePipe.transform(tstDate, 'dd.MM.yyyy, HH:mm:ss');
        if (this.checkSameDate(tstDate, recentTstDate)) {
            const formattedRecentTstDate = this.datePipe.transform(recentTstDate, 'HH:mm:ss');
            return notification.message + "\nSeit: " + formattedTstDate + "\nZuletzt: " + formattedRecentTstDate;
        } else {
            const formattedRecentTstDate = this.datePipe.transform(recentTstDate, 'dd.MM.yyyy, HH:mm:ss');
            return notification.message + "\nSeit: " + formattedTstDate + "\nZuletzt: " + formattedRecentTstDate;
        }
    }

    public showActorArgs(evse: Evse): string []{
        return evse.actorArgs.filter(x => x.value != "null").map(x => x.key + ": " + x.value);
    }

    public isChargingStatusLoading(evse: Evse): boolean {
        const status = this.chargingStatusInterfaces.find(e => e.evseId === evse.id);
        return status !== undefined && status.isLoading;
    }

    public async showLog(evse: Evse) {
        if (evse !== undefined) {
            await this.evseService.showLog(evse);
        }
    }

    public getHealthIcon(evse: Evse): string {
        return this.evseHealthDataUtils.getEvseHealthIcon(evse);
    }

    private checkSameDate(date1: Date, date2: Date): boolean {
        const formattedDate1 = date1.toISOString().split('T')[0];
        const formattedDate2 = date2.toISOString().split('T')[0];
        return formattedDate1 === formattedDate2;
    }

    private updateChargingStatus(evseId: number, chargingStatus: ChargingSession.StateEnum, isLoading: boolean): void {
        const status = this.chargingStatusInterfaces.find(e => e.evseId === evseId);
        if (status) {
            status.chargingStatus = chargingStatus;
            status.isLoading = isLoading;
        } else {
            this.chargingStatusInterfaces.push({evseId: evseId, chargingStatus: chargingStatus, isLoading: isLoading});
        }
    }

    /** Whether some of the visible rows are selected. Also returns true if no there are no visible rows */
    areSomeSelected() {
        if (this.dataSource.filteredData.length == 0) {
            return true;
        }
        return this.dataSource.filteredData.some(x => this.selection.isSelected(x.id));
    }

    /** Whether all visible rows are selected. */
    isAllSelected() {
        if (this.dataSource.filteredData.length == 0) {
            return false;
        }
        return !this.dataSource.filteredData.some(x => !this.selection.isSelected(x.id));
    }

    /** Selects all visible rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        this.isAllSelected() ?
            this.dataSource.filteredData.forEach(row => this.selection.deselect(row.id)) :
            this.dataSource.filteredData.forEach(row => this.selection.select(row.id));
    }

    toggleFilterBox() {
        this.showFilterBox = !this.showFilterBox;
        localStorageSave("SHOW_TABLE_FILTER_BOX", this.showFilterBox ? "true" : "false");
    }

    ngOnDestroy(): void {
        this.removeEvseSelection.unsubscribe();
    }
}
