<table *ngIf="(vehicles | async) as vehicles" mat-table [dataSource]="dataSource" class="full-width-table" matSort [trackBy]="trackBy">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dongleId">
        <th mat-header-cell *matHeaderCellDef class="col-dongle-id">Dongle ID</th>
        <td mat-cell *matCellDef="let element" class="col-dongle-id">
            {{element.dongleId}}
        </td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.vehicle?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dongleVin">
        <th mat-header-cell *matHeaderCellDef class="col-vin">VIN</th>
        <td mat-cell *matCellDef="let element" class="col-vin">
            {{element.dongleVin}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dongleVersion">
        <th mat-header-cell *matHeaderCellDef class="col-version">Version</th>
        <td mat-cell *matCellDef="let element" class="col-version">
            {{(element.detectedVehicle || "--") + " " + (element.versionArmApp || "--") + " " + (element.versionArmKernel || "--") + " " + (element.versionEsp || "--")}}
        </td>
    </ng-container>

    <ng-container matColumnDef="lastMsg">
        <th mat-header-cell *matHeaderCellDef class="col-last-msg">Letzter Kontakt</th>
        <td mat-cell *matCellDef="let element" class="col-last-msg">
            {{element.lastMsg | date}} {{element.lastMsg | date:'mediumTime'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="rssi">
        <th mat-header-cell *matHeaderCellDef class="col-rssi">RSSI</th>
        <td mat-cell *matCellDef="let element" class="col-rssi">
            <span class="wifi" *ngIf="element.vehicle && getSignal(element.vehicle, vehicles) as signal" [matTooltip]="signal.val + ' (' + (signal.tst | age) + ')'">
                <mat-icon *ngIf="signal.val < -77">network_wifi_1_bar</mat-icon>
                <mat-icon *ngIf="signal.val < -55 && signal.val >= -77">network_wifi_2_bar</mat-icon>
                <mat-icon *ngIf="signal.val < -35 && signal.val >= -55">network_wifi_3_bar</mat-icon>
                <mat-icon *ngIf="signal.val >= -35">network_wifi</mat-icon>
                <mat-hint>{{signal.val}}</mat-hint>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
