import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {MatChipListboxChange, MatChipSelectionChange} from '@angular/material/chips';
import {FormControl} from '@angular/forms';

export interface DateRange {
    start: Moment,
    end: Moment
}
export enum TimeRangePreset {
    week = "week",
    month = "month",
    year = 'year',
}

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrl: './date-selection.component.scss'
})
export class DateSelectionComponent implements OnInit {
    @Output() updateRangeEvent = new EventEmitter();
    public range: DateRange = this.getWeek(moment());
    public selectedDateRange: TimeRangePreset = TimeRangePreset.week;
    TimeRangePreset = TimeRangePreset;

    public selectedDateRangeFormControl = new FormControl();

    ngOnInit(): void {
        this.updateRangeEvent.emit(this.range);
    }

    public goForward() {
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.range.start.add(7, 'd');
                this.range = this.getWeek(this.range.start);
                break;
            case TimeRangePreset.month:
                this.range.start.add(1,'M');
                this.range = this.getMonth(this.range.start);
                break;
            case TimeRangePreset.year:
                this.range.start.add(1,'y');
                this.range = this.getYear(this.range.start);
                break;
        }
        this.updateRangeEvent.emit(this.range);
    }

    public goBackward() {
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.range.start.subtract(7, 'd');
                this.range = this.getWeek(this.range.start);
                break;
            case TimeRangePreset.month:
                this.range.start.subtract(1, 'M');
                this.range = this.getMonth(this.range.start);
                break;
            case TimeRangePreset.year:
                this.range.start.subtract(1, 'y');
                this.range = this.getYear(this.range.start);
                break;
        }
        this.updateRangeEvent.emit(this.range);
    }

    public goToday() {
        const m = moment();
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.range = this.getWeek(m);
                break;
            case TimeRangePreset.month:
                this.range = this.getMonth(m);
                break;
            case TimeRangePreset.year:
                this.range = this.getYear(m);
                break;
        }
        this.updateRangeEvent.emit(this.range);
    }

    public selectionChanged(selection: MatChipListboxChange) {
        if(!selection.value) {
            this.selectedDateRangeFormControl.patchValue(this.selectedDateRange)
            return
        }
        this.selectedDateRange = selection.value;
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                this.range = this.getWeek(this.range.start);
                break;
            case TimeRangePreset.month:
                this.range = this.getMonth(this.range.start);
                break;
            case TimeRangePreset.year:
                this.range = this.getYear(this.range.start);
                break;
        }
        this.updateRangeEvent.emit(this.range);
    }

    public getHumanReadable() :string {
        const start = moment(this.range.start);
        const end = moment(this.range.end);
        switch (this.selectedDateRange) {
            case TimeRangePreset.week:
                let format = start.month() !== end.month()? "MMM" : '';
                format += start.year() !== end.year()? " YYYY" : '';
                return start.locale("de").format("K\\W WW, DD " + format) + " - " + end.locale("de").format("DD MMM YYYY");
            case TimeRangePreset.month:
                return start.locale("de").format("MMMM YYYY");
            case TimeRangePreset.year:
                return start.locale("de").format("YYYY");
        }
    }

    public shouldForwardAvailable(): boolean {
        return this.range.end.valueOf() > moment().valueOf();
    }

    private getMonth(c: Moment): DateRange {
        return {
            start: c.clone().startOf('M'),
            end: c.clone().endOf('M')
        };
    }

    private getYear(c: Moment): DateRange {
        return {
            start: c.clone().startOf('y'),
            end: c.clone().endOf('y')
        };
    }

    private getWeek(c: Moment): DateRange {
        if (c.day() === 0) {
            c.subtract(1, 'd');
        }
        return {
            start: c.clone().startOf('week').add(1, 'd'),
            end: c.clone().endOf('week').add(1, 'd'),
        };
    }
}
