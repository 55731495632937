<table mat-table [dataSource]="rfidMatTableDataSource" class="full-width-table" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
        <td mat-cell *matCellDef="let element" class="col-id">
            {{element.id}}
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-name">Name</th>
        <td mat-cell *matCellDef="let element" class="col-name">
            {{element.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="token">
        <th mat-header-cell *matHeaderCellDef class="col-token">Token</th>
        <td mat-cell *matCellDef="let element" class="col-token">
            {{element.token}}
        </td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="col-vehicle">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-vehicle">
            {{element.vehicle?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef class="col-user">User</th>
        <td mat-cell *matCellDef="let element" class="col-user">
            {{element.user?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="invoiceReceiver">
        <th mat-header-cell *matHeaderCellDef class="col-invoiceReceiver">Kostenstelle</th>
        <td mat-cell *matCellDef="let element" class="col-invoiceReceiver">
            {{element.invoiceReceiver}}
        </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef class="col-active">Aktiv</th>
        <td mat-cell *matCellDef="let element" class="col-active">
            {{element.active ? "Ja" : "Nein"}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <button [disabled]="!element.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!element.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="rfidMatTableDataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
