import { Component, OnInit } from '@angular/core';
import {SolarSystemService} from '../../../modules/solar/service/solar-system.service';

@Component({
  selector: 'app-solar-system-no-fleet-info',
  templateUrl: './solar-system-no-fleet-info.component.html',
  styleUrls: ['./solar-system-no-fleet-info.component.scss']
})
export class SolarSystemNoFleetInfoComponent implements OnInit {
    public shouldInfoShown: boolean = false;

    constructor(private solarSystemService: SolarSystemService) { }

    async ngOnInit(): Promise<void> {
        const solarSystems = await this.solarSystemService.getAllPromise();
        solarSystems.forEach(solarSystem => {
            if (solarSystem.canEdit && solarSystem.basis === undefined) {
                this.shouldInfoShown = true;
            }
        });
    }
}
