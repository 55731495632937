import {Injectable} from '@angular/core';
import {
    ExecuteEmailSettingsChange,
    ExecuteUserAddOrUpdate,
    MailExist,
    User,
    UserExist
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {EditUserDialogComponent} from '../dialogs/edit-user-dialog/edit-user-dialog.component';
import {CreateUserDialogComponent} from '../dialogs/create-user/create-user-dialog.component';
import {EditPasswordDialogComponent} from '../dialogs/edit-password/edit-password-dialog.component';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {ApiHandler} from '../../../services/api-handlers/api-handler';
import {POLL_INTERVALS} from '../../../app.module';

@Injectable({
    providedIn: 'root'
})
export class UserService extends DialogHandler<User, ExecuteUserAddOrUpdate, ExecuteUserAddOrUpdate, {}, void, void> {

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'User', toastr, dialog, dialogService, POLL_INTERVALS.user);
    }

    public async updateEmailSettings(settings: ExecuteEmailSettingsChange, showAlerts = true) : Promise<void>{
        await this.apiService.updateUserEmailSettings(showAlerts, undefined, undefined, ApiHandler.customerId, settings).toPromise();
        return;
    }
    public async requestPasswordChange(showAlerts = true): Promise<void> {
        await this.apiService.requestPasswordChange(showAlerts).toPromise();
        return;
    }
    public async mailUsed(mail:string, showAlerts = true): Promise<MailExist>{
        return this.apiService.emailUsed(showAlerts, {eMail: mail}).toPromise();
    }
    public  usernameUsed(username:string, showAlerts = true): Promise<UserExist>{
        return this.apiService.usernameUsed(showAlerts, {userName:username}).toPromise();
    }


    protected getEditConfig(user: User): TDialogOptions<ExecuteUserAddOrUpdate, EditUserDialogComponent> {
        return {
            headline: "Benutzer bearbeiten",
            component: EditUserDialogComponent,
            executeCallback: elem => this.update(user.id, elem),
            editElement: {
                name: user.name,
                password: undefined,
                lastname: user.lastname,
                firstname: user.firstname,
                email: user.email,
                emailAlertLevel: user.emailAlertLevel,
                sendReservation: user.sendReservation,
                sendSocReachedNotification: user.sendSocReachedNotification,
                permissionGroups: user.permissionGroups?.map(pg => pg.id) || []
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteUserAddOrUpdate, CreateUserDialogComponent> {
        return {
            headline: "Benutzer anlegen",
            component: CreateUserDialogComponent,
            executeCallback: elem => this.create(elem),
            editElement: {
                name: "",
                password: undefined,
                firstname: "",
                lastname:"",
                email: undefined,
                emailAlertLevel: 0,
                sendReservation: false,
                sendSocReachedNotification: false,
                permissionGroups: []
            }
        };
    }

    public async showEditPwDialog(user: User): Promise<void> {
        const config = {
            edit: true,
            headline: "Passwort setzen",
            component: EditPasswordDialogComponent,
            executeCallback: (elem: ExecuteUserAddOrUpdate) => this.update(user.id, elem),
            editElement: {
                name: user.name,
                password: undefined,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                emailAlertLevel: user.emailAlertLevel,
                sendReservation: user.sendReservation,
                sendSocReachedNotification: user.sendSocReachedNotification,
                permissionGroups: user.permissionGroups?.map(pg => pg.id) || []
            }
        };
        await this.showDialog(config, "Passwort gesetzt");
    }
}
