<div [formGroup]="formGroup" class="main-div d-flex flex-column align-items-stretch gap-3">
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="nameCtrl">
            <mat-error *ngIf="formGroup.get('nameCtrl')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Korrekturfaktor für Reichweitenschätzung</mat-label>
            <mat-select formControlName="factorSelectCtrl" (selectionChange)="changeRangeFactorSelection($event)">
                <mat-option *ngFor="let defaultRangeFactor of rangeFactors; trackBy: trackByValue"
                            [value]="defaultRangeFactor.value">{{defaultRangeFactor.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="contain-div d-flex flex-row gap-3">
        <mat-form-field appearance="fill" *ngIf="(algoConfigs | async) as algoConfigs; else loading" class="flex-grow-1">
            <mat-label>Ladeplan Algorithmus</mat-label>
            <mat-select formControlName="algoCtrl">
                <mat-option [value]="-1">--Alter Algorithmus--</mat-option>
                <mat-option *ngFor="let algoConfig of algoConfigs" [value]="algoConfig.id" >{{ algoConfig.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-grow-1">
            <mat-label>Korrekturfaktor für Reichweitenschätzung</mat-label>
            <input matInput type='number' min="0" step="0.01" formControlName="rangeFactorCtrl">
            <mat-error *ngIf="formGroup.get('rangeFactorCtrl')?.errors?.required as error">{{error}}</mat-error>
        </mat-form-field>
    </div>
    <mat-checkbox formControlName="rfidTrainingModeCtrl">
        RFID Karten lernen? (Alle unbekannten Karten werden erlaubt und gelernt)
    </mat-checkbox>
</div>
<ng-template #loading>Bitte warten</ng-template>
