import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {IComplexLiveValue} from './data-table.component';
import {AgePipe} from '../../../helper/age.pipe';
import {num} from '../../../helper/util-functions';
import {DataValueBool, DataValueChar, DataValueNum} from '@io-elon-common/frontend-api';

type Formatter<T> = (val: T) => IComplexLiveValue | string;
type SimpleFormatter<T> = (val: T) => string;

@Injectable({
    providedIn: 'root'
})
export class FormatterFactoryService {

    constructor(
        private readonly agePipe: AgePipe,
        private readonly datePipe: DatePipe
    ) {
    }

    public createDataValueFormatter<T>(valFormatter: SimpleFormatter<T>): Formatter<DataValueChar | DataValueBool | DataValueNum> {
        return (val: DataValueChar | DataValueBool | DataValueNum) => {
            if (val === null || val === undefined) {
                return {
                    showAgeIcon: false,
                    age: "",
                    value: '--'
                };
            }
            const age = Date.now() - val.tst;
            if (age > 1000 * 60 * 3) { // 3 Minuten
                // @ts-ignore
                return {
                    showAgeIcon: true,
                    age: this.agePipe.transform(val.tst),
                    // @ts-ignore
                    value: valFormatter(val.val)
                }
            }
            // @ts-ignore
            return {
                showAgeIcon: false,
                age: this.agePipe.transform(val.tst),
                // @ts-ignore
                value: valFormatter(val.val)
            }
        };
    }

    public createUnitFormatter(unit: string): SimpleFormatter<number> {
        return (val: number | string | null | undefined) => {
            let usedUnit = unit;
            if (val == null) {
                return '--';
            }
            let numVal = num(val);
            if (numVal > 10000 && !unit.startsWith("k")) {
                numVal = numVal / 1000;
                usedUnit = 'k' + usedUnit;
            }
            return numVal.toFixed(1) + ' ' + usedUnit;
        };
    }

    public createPlainFormatter(): SimpleFormatter<any> {
        return (val: any) => {
            if(val == null) {
                return "--"
            }
            return val + '';
        }
    }

    public createDateValueFormatter(): SimpleFormatter<number> {
        return (val: number) => val ? this.datePipe.transform(val) as string : "--";
    }

    public createUnitDataValueFormatter(unit: string): Formatter<DataValueChar | DataValueBool | DataValueNum> {
        return this.createDataValueFormatter(this.createUnitFormatter(unit));
    }

    public createDateDataValueFormatter(): Formatter<DataValueChar | DataValueBool | DataValueNum> {
        return this.createDataValueFormatter(this.createDateValueFormatter());
    }

    public createPlainDataValueFormatter(): Formatter<DataValueChar | DataValueBool | DataValueNum> {
        return this.createDataValueFormatter(this.createPlainFormatter());
    }
}
