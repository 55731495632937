<footer>
    <span class="footer-left" (click)="toggleTime()" [style]="shouldShowTime()?'opacity:100':''" [matTooltip]="shouldShowTime()?'Die Uhrzeit dieses Computers weicht von der Uhrzeit des IO-Elon-Servers um '+getServerTimeDeltaString()+' Sekunden ab.\nBitte stelle sicher, das die Uhrzeit auf dem Computer richtig eingestellt ist.\nEine genaue Uhrzeit wird für die korrekte Darstellung einige Diagramme und andere Angaben von Zeitpunkten benötigt.':''">
        <svg width="20" height="20" viewBox="0 0 32 40" xmlns="http://www.w3.org/2000/svg" stroke="gray">
            <circle r="11" cx="12" cy="20" stroke-width="2" fill="none"></circle>
            <path d="M 12 13 v 7 h 5 M 28 2 v 14 m 0 5 v 1" fill="none" stroke-width="3" stroke-linejoin="round" stroke-linecap="round"></path>
        </svg>
        {{getServerTimeDeltaString()}}s
    </span>
    <span class="footer-right">© IO-Dynamics GmbH {{year}}</span>
</footer>
