import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {MeterHistory, MeterHistoryData, MeterValue} from '@io-elon-common/frontend-api';
import {MeterService} from '../../service/meter.service';
import {FormControl} from '@angular/forms';
import Annotation = dygraphs.Annotation;

interface IMeterField {
    name: string;
    pretty: string;
    disabled: boolean;
  }


interface IMeterGraphData {
    data: Array<Array<HistoryCellData>>;
    events: Annotation[];
}



@Component({
    selector: 'app-meter-history-graph',
    templateUrl: './meter-history-graph.component.html',
    styleUrls: ['./meter-history-graph.component.scss']
})
export class MeterHistoryGraphComponent extends AbstractHistoryGraph<IMeterGraphData>  implements OnInit {

    @Input()
    public meterId!: number;

    public value!: string;

    public formControl = new FormControl();

    public keys: Map<string, string> = new Map();
    private units = new Map([["i","A"], ["u","V"], ["p","kW"], ["m","kWh"], ["c",""]]);
    private cnt = 3;
    public autoReload = false;
    public autoReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    public bindedkeys!: Array<IMeterField>;

    public constructor(
        private readonly element: ElementRef,
        private readonly meterService: MeterService
    ) {
        super();
    }

    public async ngOnInit() {
        const fields = (await this.meterService.getFields(this.meterId)).fields;
        fields.forEach(field=> this.keys.
            set(this.uncapitalize(field.name),
                this.formatPrettyName(field.displayName, field.unit))
            );

        this.bindedkeys = Array.from(this.keys).map(([key, value])=>({name: key, pretty: value, disabled: false}));

        this.formControl.reset(Array.of("i1", "i2", "i3"));

        await this.init(this.element);
    }

    private uncapitalize(s: string): string{
        return s.charAt(0).toLowerCase() + s.slice(1);
    }

    private formatPrettyName(displayName: string ,unit: string|undefined): string{
        return (unit === undefined || unit === "") ? displayName: displayName+" ("+unit+")";
    }

    public makePretty(key: string): string {
        return this.keys.get(key) || key;
    }

    public async update() {
        if(this.formControl.value == null || this.formControl.value.length < this.cnt){
            const start = this.start;
            const end = this.end;
            await this.init(this.element);
            this.dygraph.updateOptions({dateWindow: [start, end]});
            this.loading = 0;

        } else {
            const conf = await this.getConfig();
            this.dygraph.updateOptions(conf);
            await this.updateToRange(this.start, this.end, false, true);

        }
        this.changeFieldStatues();
        this.cnt = this.formControl.value == null ? 0: this.formControl.value.length;

    }

    public defaultStart(): number {
        return Date.now() - 1000 * 60 * 60 * 365; // 1 Jahr
    }

    public defaultEnd(): number {
        return Date.now()// Jetzt
    }

    getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<dygraphs.Options> {
        const labels = ['x'];
        const series:any = {};
        const axes:any = {};
        const axesMap:Map<string, number> = new Map();

        this.formControl.value.forEach((k: keyof MeterValue) => {
            if(this.supportsPrediction(k)) {
                labels.push(this.keys.get(k) as string);
                labels.push(this.keys.get(k) + " (erwartet)");
            } else {
                labels.push(this.keys.get(k) as string);
            }
        })
        labels.push('Events');

        for(const k of this.formControl.value){
            for (const i of ["i", "u", "p", "c", "m"]){
                const key = this.keys.get(k);
                if(key === undefined) {
                    continue;
                }
                if (k.startsWith(i)){

                    const y = this.getAxes(i, axesMap, axes);
                    if(y !== ""){
                        if(this.supportsPrediction(k)) {
                            series[key] = {
                                stepPlot: true,
                                axis: y
                            };
                            series[key + " (erwartet)"] = {
                                stepPlot: true,
                                axis: y,
                                strokePattern: [2,2]
                            };
                        } else {
                            series[key] = {
                                stepPlot: true,
                                axis: y
                            };
                        }
                    }
                }
            }
        }


        return {
            customBars: true,
            axes,
            labels,
            colors: this.getGraphColors(),
            series,
            underlayCallback: (canvas, area, graph) => {
                const nowX = graph.toDomXCoord(Date.now());
                if(nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            }
        };
    }

    private getAxes(identifier: string, m: Map<string, number>, axes: any): string {
        if (m.has(identifier)){
            return "y"+ m.get(identifier);
        }
        if (m.size === 0) {
            m.set(identifier,1);
            const unit = this.units.get(identifier);

            axes.y = {axisLabelFormatter: (w: number | Date) => {
                return '<span>' + ((w as number)).toFixed(1) + ' '+unit+'</span>';
            }};

            return "y1";
        }
        if (m.size === 1) {
            m.set(identifier,2);
            const unit = this.units.get(identifier);

            axes.y2 = {axisLabelFormatter: (w: number | Date) => {
                return '<span>' + ((w as number)).toFixed(1) + ' '+unit+'</span>';
            }};

            return "y2";
        }

        return "";
    }

    private changeFieldStatues(){
        if(this.formControl.value == null || new Set(this.formControl.value.map((v:string)=> v[0])).size <= 1){
            this.bindedkeys.forEach(k => {k.disabled=false;});
        } else {
            for (const key of this.bindedkeys) {
                key.disabled = !this.formControl.value.some((v: string) => key.name.startsWith(v[0]));
            }
        }
    }

    protected async getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }

    protected async loadData(start: number, end: number): Promise<IMeterGraphData> {
        const history: MeterHistory = await this.meterService.getHistory(this.meterId, start, end);

        const dataMapped = this.createData(start, end, history.data, this.formControl.value);
        // @ts-ignore
        dataMapped.sort((a, b)=> a[0]-b[0]);
        const data = this.joinLines(dataMapped);
        return {
            data,
            events: []
        };
    }

    private createRow(gapDistance: number, index: number, series: number, data: MeterHistoryData, key: keyof MeterValue): HistoryCellData[][] {
        switch (key.toString()) {
            case "cosPhi1":
                return this.mapToArray(data.cosPhi1 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "cosPhi2":
                return this.mapToArray(data.cosPhi2 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "cosPhi3":
                return this.mapToArray(data.cosPhi3 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "cosPhiSum":
                return this.mapToArray(data.cosPhiSum || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "u1":
                return this.mapToArray(data.u1 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "u2":
                return this.mapToArray(data.u2 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "u3":
                return this.mapToArray(data.u3 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueTotal":
                return this.mapToArray(data.meterValueTotal || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueSinceBootIn":
                return this.mapToArray(data.meterValueTotalIn || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueSinceBoot":
                return this.mapToArray(data.meterValueSinceBoot || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueTotalIn":
                return this.mapToArray(data.meterValueTotalIn || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "iSum":
                return this.mapToArray(data.iSum || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueSinceBootOut":
                return this.mapToArray(data.meterValueSinceBootOut || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "meterValueTotalOut":
                return this.mapToArray(data.meterValueTotalOut || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "peak":
                return this.mapToArray(data.peak || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "pIn":
                return this.mapToArray(data.pIn || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "pOut":
                return this.mapToArray(data.pOut || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "pSum":
                return this.mapToArray(data.pSum || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "i1":
                return this.mapToArray(data.i1 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "i2":
                return this.mapToArray(data.i2 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "i3":
                return this.mapToArray(data.i3 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "p1":
                return this.mapToArray(data.p1 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "p2":
                return this.mapToArray(data.p2 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
            case "p3":
                return this.mapToArray(data.p3 || [], 'time', 'val', 'min', 'max', index, series, undefined, gapDistance);
        }
        console.error("Unexpected Key:", key)
        return [];
    }


    private createData(start: number, end: number, data: MeterHistoryData, keys: Array<keyof MeterValue>) : HistoryCellData[][]{
        const gapDistance = (end -start)/2;
        const indexCount = keys.length + keys.filter(key=> this.supportsPrediction(key)).length+1;
        let arr: HistoryCellData[][] = [];
        const time = Date.now();
        let predOffset = 0;
        keys.forEach((key, i) => {
            if(this.supportsPrediction(key)) {
                const history = this.createRow(gapDistance, i+predOffset, indexCount, data, key)
                // @ts-ignore
                    .filter(d=>d[0]<=time);
                predOffset++;
                if (history.length > 0) {
                    const prediction = this.createRow(gapDistance, i + predOffset, indexCount, data, key)
                        // @ts-ignore
                        .filter(d => d[0] >= history[history.length - 1][0]);
                    arr = arr.concat(history).concat(prediction);
                }
            } else {
                arr = arr.concat(this.createRow(gapDistance, i + predOffset, indexCount, data, key));
            }
        });
        return arr;
    }

    private supportsPrediction(field: keyof MeterValue): boolean {
        switch (field) {
            case "cosPhi1":
            case "cosPhi2":
            case "cosPhi3":
            case "cosPhiSum":
            case "u1":
            case "u2":
            case "u3":
            case "prediction":
            case "id":
            case "meterValueTotal":
            case "meterValueSinceBootIn":
            case "meterValueSinceBoot":
            case "meterValueTotalIn":
            case "iSum":
            case "meterValueSinceBootOut":
            case "meterValueTotalOut":
            case "peak":
            case "pIn":
            case "pOut":
            case "pSum":
            case "tst":
                return false;
            case "i1":
            case "i2":
            case "i3":
            case "p1":
            case "p2":
            case "p3":
                return true;
        }
        console.error("Unexpected Key:", field)
        return false;
    }

    private getColorOfField(field: string): string {
        switch (field) {
            case "i1":
            case "i1 (erwartet)":
                return "#ffAA00";
            case "i2":
            case "i2 (erwartet)":
                return "#ffBB00";
            case "i3":
            case "i3 (erwartet)":
                return "#ffCC00";
            case "p1":
            case "p1 (erwartet)":
                return "#35AAbd";
            case "p2":
            case "p2 (erwartet)":
                return "#35BBbd";
            case "p3":
            case "p3 (erwartet)":
                return "#35CCbd";
            case "pSum":
                return "#35DDbd";
            case "pIn":
                return "#35EEbd";
            case "pOut":
                return "#35FFbd";
            case "u1":
                return "#345fed";
            case "u2":
                return "#343eed";
            case "u3":
                return "#332fed";
            case "cosPhi1":
                return "#89a650";
            case "cosPhi2":
                return "#89b750";
            case "cosPhi3":
                return "#89c850";
            case "cosPhiSum":
                return "#89d950";
            case "meterValueSinceBoot":
                return "#c73026";
            case "meterValueSinceBootIn":
                return "#c74126";
            case "meterValueSinceBootOut":
                return "#c75226";
            case "meterValueTotal":
                return "#c76326";
            case "meterValueTotalIn":
                return "#c77426";
            case "meterValueTotalOut":
                return "#c78526";
            default:
                return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
        }

}

    private getGraphColors(): string[] {
        const colors = [];
        for (const key of this.formControl.value) {
            const color = this.getColorOfField(key);
            colors.push(color);
            if (this.supportsPrediction(key)) colors.push(color);
        }
        return colors;
    }
}
