import {Component, Input} from '@angular/core';
import {IEditForm} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {ExecuteSTecAccountAddOrUpdate, STecAccountTestResult} from "@io-elon-common/frontend-api";
import {STecAccountService} from "../../service/s-tec-account.service";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../../../shared/guards/auth.service";

@Component({
    selector: 'app-edit-s-tec-account-dialog',
    templateUrl: './edit-s-tec-account-dialog.component.html',
    styleUrls: ['./edit-s-tec-account-dialog.component.scss']
})
export class EditSTecAccountDialogComponent implements IEditForm<ExecuteSTecAccountAddOrUpdate> {

    @Input()
    public data!: ExecuteSTecAccountAddOrUpdate;
    public testRunning = false;
    public testResult? : STecAccountTestResult;
    public isDev: boolean;

    constructor(
        private readonly sTecService: STecAccountService,
        private readonly toastService: ToastrService,
        private readonly authService: AuthService
    ) {
        this.isDev = this.authService.isDeveloper();
    }

    public async test() {
        if(this.validate().length !== 0) {
            this.toastService.error("Bitte vor dem Test alle Felder ausfüllen.")
            return;
        }
        try {
            this.testRunning = true;
            this.testResult = await this.sTecService.testAccount(this.data);
        } finally {
            this.testRunning = false;
        }
    }

    validate(): string[] {
        const errors = [];
        if(!this.data.name) {
            errors.push("Name ist leer")
        }
        if(!this.data.baseUrl) {
            errors.push("URL ist leer")
        }
        if(!this.data.webTicket) {
            errors.push("Web Ticket wurde nicht ausgefüllt.")
        }
        return errors;
    }
}
