<mat-toolbar [class.shadow]="shadow" color="iodynamics">
    <mat-toolbar-row class="d-flex flex-row">
        <app-breadcrumb [selectedFleetName]="getFleetName(fleetId)" class="align-self-start flex-grow-1"></app-breadcrumb>


        <div class="mat-body toolbar-controls d-flex flex-row align-self-end align-items-center gap-2 flex-grow-0">

            <a mat-raised-button (click)="newBug()" target="_blank" *ngIf="authService.isDeveloper()" color="primary">
                Bug Melden
            </a>
            <a mat-raised-button (click)="newEnhancement()" target="_blank" *ngIf="authService.isDeveloper()" color="primary">
                Verbesserung Melden
            </a>

            <div class="search">
                <span class="fa fa-search" (click)="gotToSearchPage()" ></span>
                <input matInput type="text" placeholder="Suche" [(ngModel)]="keyword" (keydown)="textChanged($event)">
            </div>

            <div><!-- just some whitespace --></div>
            <mat-form-field class="mat-body transparent" style="padding-top: 20px" *ngIf="fleetHierarchy | async as fleetHierarchy; else noFleets">
                <mat-label>{{selectedCustomerName()}}</mat-label>
                <mat-select [panelClass]="'extra-long-select-panel'"
                            [ngModel]="fleetService.selectedFleet | async"
                            (selectionChange)="setSelectedFleet($event.value)">
                    <mat-optgroup class="customer-opt-group" *ngFor="let c of getCustomerList(fleetHierarchy); trackBy:trackByCustomerId" [style.margin-left]="c.margin + 'px'" [style.display]="c.visibility ? 'block' : 'none'" >
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            {{c.customerName}}
                            <mat-icon *ngIf="isReseller" class="editCustomer" matTooltip="Bearbeiten" (click)="editCustomer(c)">edit</mat-icon>
                        </div>
                        <mat-option *ngFor="let f of c.fleets; trackBy:trackById" [value]="f.id">{{f.name}}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
            <ng-template #noFleets>
                Keine Flotten!
            </ng-template>
            <button mat-button matTooltip="Neuer Sub-Kunde" class="flex-grow-0" (click)="createCustomer()" *ngIf="isReseller">
                <mat-icon class="me-0">add</mat-icon>
            </button>
            <button mat-button class="user" (click)="editUser()">
                <mat-icon class="me-0">
                    account_circle
                </mat-icon>&nbsp;
                <span *ngIf="(user | async) as user; else loadingUsername">{{user | firstName}}</span>
                <ng-template #loadingUsername>--</ng-template>
            </button>
            <span *ngIf="(notifications | async) as notifications">
                <button mat-button aria-label="Notification" [matMenuTriggerFor]="notificationsMenu">
                    <mat-icon
                        class="me-0"
                        [matBadge]="notifications.activeNotifications.length"
                        [matBadgeHidden]="!notifications.activeNotifications.length"
                        matBadgeOverlap="true"
                        [class]="getBadgeClassObj(notifications.activeNotifications)"
                        svgIcon="notification"
                    ></mat-icon>
                </button>
                <mat-menu #notificationsMenu="matMenu">
                    <ng-template matMenuContent>
                        <app-notification-menu-line *ngFor="let notification of notifications.activeNotifications"
                                                    [notification]="notification"></app-notification-menu-line>
                        <app-notification-menu-line *ngFor="let notification of notifications.mutedNotifications"
                                                    [notification]="notification"></app-notification-menu-line>
                        <a class="clear" (click)="clearNotifications()">Alle löschen</a>
                    </ng-template>
                </mat-menu>
            </span>
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="me-0">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let m of menuAll">
                    <a *ngIf="checkPermission(m)" mat-menu-item [routerLink]="m.link" routerLinkActive="active-link">
                        <app-icon [type]="m.iconType" [icon]="m.icon"></app-icon>
                        <span class="title">{{m.text}}</span>
                    </a>
                </ng-container>


                <ng-container *ngIf="authService.isDeveloper()">
                    <!-- Ab hier Zeug für Entwickler -->
                    <hr *ngIf="authService.isDeveloper()"/>

                    <ng-container *ngFor="let m of menuDev">
                        <!-- skip permission check, we are a developer -->
                        <a mat-menu-item [routerLink]="m.link" routerLinkActive="active-link">
                            <app-icon [type]="m.iconType" [icon]="m.icon"></app-icon>
                            <span class="title">{{m.text}}</span>
                        </a>
                    </ng-container>
                </ng-container>

                <button mat-menu-item (click)="refreshSolarPanels()"
                        *ngIf="authService.isDeveloper()">
                    <mat-icon>compare_arrows</mat-icon>
                    Solardaten neu laden
                </button>

                <button mat-menu-item *ngIf="authService.isDeveloper()" (click)="rotateTheme()">
                    <mat-icon>
                        cached
                    </mat-icon>
                    Theme
                </button>
                <hr/>
                <button (click)="onLogout()" mat-menu-item>
                    <mat-icon>power_settings_new</mat-icon>
                    Abmelden
                </button>
                <div class="version" *ngIf="(systemInfo | async) as systemInfo">Version:
                    <br>B:<b>{{systemInfo.version}}</b>
                    <br>F:<b>{{versionFrontend}}</b>(<b>{{systemInfo.apiVersion}}</b>)
                </div>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
