import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {
    AbstractHistoryGraph,
    BackgroundLegend,
    HistoryCellData
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {SolarSystemHistory} from "@io-elon-common/frontend-api";
import {SolarSystemService} from "../../service/solar-system.service";
import Annotation = dygraphs.Annotation;

interface ISolarHistoryGraphData{
    data: Array<Array<HistoryCellData>>;
    events: Annotation[];
}

@Component({
  selector: 'app-solar-history-graph',
  templateUrl: './solar-history-graph.component.html',
  styleUrls: ['./solar-history-graph.component.scss']
})
export class SolarHistoryGraphComponent extends AbstractHistoryGraph<ISolarHistoryGraphData> implements OnInit {

    @Input()
    public solarSystemId!: number;

    @Input()
    historicSystemForecast: boolean = false;

    @Input()
    public solarPanelNames!: string[];

    public autoReload = false;
    public autoReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(
        private readonly element: ElementRef,
        private readonly solarSystemService: SolarSystemService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.init(this.element);
    }

    public defaultStart(): number {
        return Date.now() - 1000 * 60 * 60 * 24; // 1 Day
    }

    public defaultEnd(): number {
        return Date.now() + 1000 * 60 * 60 * 24; // 1 Day
    }

    getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<dygraphs.Options> {
        let labels = ['x',
            'Gesamtvorhersage',
            'Gemessen',
        ];
        let colors = [
            '#ffc800',
            '#bbb8b8'
        ];
        let series: {[seriesName: string] :dygraphs.PerSeriesOptions} = {
            'Gesamtvorhersage': {
                axis: 'y1',
                drawPoints: false,
                strokePattern: [6,6],
                fillGraph: true,
            },
            'Gemessen': {
                axis: 'y1',
                drawPoints: false,
                fillGraph: true,
            }
        };
        for (let i = 0; i < this.solarPanelNames.length; i++) {
            let name = 'Vorhersage '+this.solarPanelNames[i];
            labels[3 + i] = name
            colors[2 + i] = '#2020b8';
            series[name] = {
                axis: 'y1',
                drawPoints: false,
                fillGraph: false,
            }
        }
        return {
            labels,
            colors,
            series,
            axes: {
                y: {
                    axisLabelFormatter: (w: number | Date) => {
                        return '<span>' + ((w as number)/1000).toFixed(1) + ' kW</span>';
                    }
                }
            },
            connectSeparatedPoints: true,
            underlayCallback: (canvas, area, graph) => {
                const nowX = graph.toDomXCoord(Date.now());
                if (nowX > -10 && nowX < area.w) {
                    this.drawNowMarker(canvas, nowX, area.y, 2, area.h);
                }
            },
        };
    }

    protected async getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }
    protected async loadData(start: number, end: number): Promise<ISolarHistoryGraphData> {
        //const peaks: PeakHistory = await this.powerSupplyService.getPeaksHistory(this.powerSupplyId, start, end);
        const history: SolarSystemHistory = await this.solarSystemService.getSolarSystemHistory(this.solarSystemId, start, end);

        let dataMapped: HistoryCellData[][] = [];
        let panelCount = history.forecastPerPanel?.length;

        let index = 0;
        history.forecast.forEach(d => {
            let date = new Date(d.tst);
            if (this.historicSystemForecast || date.getTime() > new Date().getTime() - 5 * 60 * 1000) {
                dataMapped[index] = [date, d.power, null];
                for (let p = 0; p < panelCount; p++) {
                    dataMapped[index][3 + p] = null;
                }
                index++;
            }
        });
        history.measured.forEach(d => {
            dataMapped[index] = [new Date(d.tst), null, d.power];
            for (let p = 0; p < panelCount; p++) {
                dataMapped[index][3+p] = null;
            }
            index++;
        });
        let panelIndex = 0;
        history.forecastPerPanel.forEach(d => {
            d.values.forEach(d => {
                dataMapped[index] = [new Date(d.tst), null, null];
                for (let p = 0; p < panelCount; p++) {
                    if (p == panelIndex) {
                        dataMapped[index][3 + p] = d.power;
                    } else {
                        dataMapped[index][3 + p] = null;
                    }
                }
                index++;
            })
            panelIndex++;
        })
        // @ts-ignore
        dataMapped.sort((a, b) => a[0] - b[0]);
        dataMapped.forEach(d => {
            d[0] = new Date((Math.floor((d[0] as Date).getTime() / 10000)) * 10000);
        })
        const dataJoined = this.joinLines(dataMapped);

        return {
            data: dataJoined,
            events: []
        };
    }
}
