import {Component, OnInit} from '@angular/core';
import {UnknownCpiService} from "../../service/unknown-cpi.service";
import {UnknownOcppClient} from "@io-elon-common/frontend-api";
import {BehaviorSubject} from "rxjs";
import {FleetService} from "../../../vehicle/service/fleet.service";

@Component({
    selector: 'app-unknown-cpis-list-view',
    templateUrl: './unknown-cpis-list-view.component.html',
    styleUrls: ['./unknown-cpis-list-view.component.scss']
})
export class UnknownCpisListViewComponent implements OnInit {

    public unknownCpis!: BehaviorSubject<UnknownOcppClient[] | undefined>;
    public host = location.hostname;
    public secure = location.protocol === "https:";
    public fleetId!: BehaviorSubject<number | undefined>

    public constructor(
        private readonly unknownCpiService: UnknownCpiService,
        private readonly fleetService: FleetService
    ) {
    }

    public ngOnInit(): void {
        this.unknownCpis = this.unknownCpiService.getAll();
        this.fleetId = this.fleetService.selectedFleet;
    }
}
