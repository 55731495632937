<mat-dialog-content>
    <div class="dialog-head">
        <h2 class="mat-h2">Protokoll</h2>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        <h4><app-evse-link [evseId]="evseId"></app-evse-link></h4>
    </div>

    <div class="log-container">
        <div class="w-100">
            <pre id="scroll-pre" class="w-100">{{log}}</pre>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-row justify-content-end" >
    <button mat-raised-button color="primary" type="submit" (click)="downloadLog()" style="margin-right: 15px">
        Protokoll Herunterladen
    </button>
</mat-dialog-actions>
