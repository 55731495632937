<div class="d-flex flex-column gap-3" *ngIf="(vehicle | async) as vehicle; else loading">
    <app-boxes [boxes]="boxes"></app-boxes>
    <div class="d-flex flex-row justify-content-between gap-3">
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu"><i class="fas fa-gear"></i></button>
        <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item (click)="actions()" *ngIf="vehicle.supportedActions.length > 0">Aktionen</button>
            <button [disabled]="!vehicle.canEdit || vehicle.id < 0" mat-menu-item (click)="edit()">
                Bearbeiten
            </button>
            <button [disabled]="!vehicle.canDelete || vehicle.id < 0" mat-menu-item (click)="delete()">
                Löschen
            </button>
        </mat-menu>
        <div class="flex-grow-0">
            <img *ngIf="vehicle"
                 src="/img/vehicles/{{vehicle.img}}"
                 style="object-fit: contain; max-height: 200px"
                 alt="Vehicle Picture">
        </div>
        <div class="flex-grow-0" *ngIf="vehicle?.liveData.soh">
            <app-radial-bar [value]="vehicle.liveData.soh.val" [label]="'Batteriegesundheit'" [max]="100" [unit]="'%'"></app-radial-bar>
        </div>
        <div class="flex-grow-0" *ngIf="vehicle?.liveData.estimatedSocU">
            <app-radial-bar [value]="vehicle.liveData.estimatedSocU.val" [label]="'Ladestand'" [max]="100" [unit]="'%'"></app-radial-bar>
        </div>
        <div class="flex-grow-0" *ngIf="vehicle">
            <app-radial-bar [value]="vehicle.liveData.rangeNow?.val" [label]="'Reichweite'" [max]="vehicle.liveData.rangeFull?.val" [unit]="'km'"></app-radial-bar>
        </div>
    </div>
    <div>
        <div>
            <mat-card appearance="outlined" *ngIf="vehicle.id >= 0">
                <h2 class="center">Ladeplan und Verlauf</h2>
                <div class="actions">
                    <mat-checkbox #autoReload checked="checked">Automatisch neu laden</mat-checkbox>
                    <app-graph-date-picker *ngIf="graph1" [graph]="graph1"></app-graph-date-picker>
                    <app-graph-date-picker *ngIf="graph2" [graph]="graph2"></app-graph-date-picker>
                    <app-graph-date-picker *ngIf="graph3" [graph]="graph3"></app-graph-date-picker>
                    <app-graph-date-picker *ngIf="graph4" [graph]="graph4"></app-graph-date-picker>
                    <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
                </div>
                <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="setSelectedTab($event)" mat-stretch-tabs="false" mat-align-tabs="start">
                    <mat-tab label="Übersicht (Leistung)">
                        <ng-template matTabContent>
                            <div style="height: 390px">
                                <app-vehicle-history-graph #graph2 *ngIf="vehicle" [vehicleId]="vehicle.id" [selectedUnit]="powerUnits.W" [(autoReload)]="autoReload.checked"></app-vehicle-history-graph>
                            </div>
                            <app-background-legend *ngIf="graph2" [matchingGraph]="graph2"></app-background-legend>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Übersicht (Strom)">
                        <ng-template matTabContent>
                            <div style="height: 390px">
                                <app-vehicle-history-graph #graph1 *ngIf="vehicle" [vehicleId]="vehicle.id" [selectedUnit]="powerUnits.A" [(autoReload)]="autoReload.checked"></app-vehicle-history-graph>
                            </div>
                            <app-background-legend *ngIf="graph1" [matchingGraph]="graph1"></app-background-legend>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Batteriegesundheit">
                        <ng-template matTabContent>
                            <div style="height: 390px">
                                <app-soh-history-graph #graph3 *ngIf="vehicle" [vehicleId]="vehicle.id" [(autoReload)]="autoReload.checked"></app-soh-history-graph>
                            </div>
                            <app-background-legend *ngIf="graph3" [matchingGraph]="graph3"></app-background-legend>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="12V Batteriespannung">
                        <ng-template matTabContent>
                            <div style="height: 390px">
                                <app-u12-history-graph #graph4 *ngIf="vehicle" [vehicleId]="vehicle.id" [(autoReload)]="autoReload.checked"></app-u12-history-graph>
                            </div>
                            <app-background-legend *ngIf="graph4" [matchingGraph]="graph4"></app-background-legend>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>
    </div>
    <div>
        <app-vehicle-chargings-table class="with-background" *ngIf="vehicle" [vehicleId]="vehicle.id"></app-vehicle-chargings-table>
    </div>
    <div class="d-flex flex-row align-content-between align-items-start gap-3">
        <div class="with-background flex-grow-1">
            <app-data-table *ngIf="vehicle" [obj]="vehicle" [keys]="vehicleDataTableKeys"
                            headline="Live Daten"></app-data-table>
        </div>
        <app-event-log-table *ngIf="vehicle.id >= 0" class="with-background flex-grow-1" [loadDataCb]="loadHistoryCb"></app-event-log-table>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
