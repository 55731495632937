import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Box, Fleet, Vehicle} from '@io-elon-common/frontend-api';
import {Keys} from '../../../../shared/components/tables/data-table/data-table.component';
import {FormatterFactoryService} from '../../../../shared/components/tables/data-table/formatter-factory.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {VehicleService} from '../../service/vehicle.service';
import {EvseUtilsService} from '../../../../shared/helper/evse-utils.service';
import {MatDialog} from '@angular/material/dialog';
import {VehicleActionDialogComponent} from '../../dialogs/vehicle-action-dialog/vehicle-action-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../../services/api-handlers/api.service';
import {DatePipe} from '@angular/common';
import {
    AbstractHistoryGraph
} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import {num} from '../../../../shared/helper/util-functions';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";
import {LocalStorageField, localStorageGet, localStorageSave} from 'src/app/shared/helper/typed-local-storage';
import {PowerUnits} from 'src/app/shared/helper/power-units';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
    selector: 'app-vehicle-details-view',
    templateUrl: './vehicle-details-view.component.html',
    styleUrls: ['./vehicle-details-view.component.scss']
})
export class VehicleDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild('graph1', { static: false }) graph1!: AbstractHistoryGraph<any>;
    @ViewChild('graph2', { static: false }) graph2!: AbstractHistoryGraph<any>;
    @ViewChild('graph3', { static: false }) graph3!: AbstractHistoryGraph<any>;
    @ViewChild('graph4', { static: false }) graph4!: AbstractHistoryGraph<any>;

    public vehicle!: BehaviorSubject<Vehicle | undefined>;
    private vehicleSubscription!: Subscription;
    private routerEventSubscription!: Subscription;
    public selectedTab!: number;
    public powerUnits: typeof PowerUnits = PowerUnits;

    vehicleDataTableKeys!: Keys<Vehicle>;

    boxes!: (Box & {link?: string})[];
    private currentVehicleId: number | undefined = undefined;

    // @ts-ignore
    loadHistoryCb = (start: number, end: number) => this.apiService.getVehicleEvents(true, this.vehicle.getValue()?.id, start, end, undefined, undefined, ApiHandler.customerId).toPromise();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly apiService: ApiService,
        private readonly vehicleService: VehicleService,
        private readonly formatterFactory: FormatterFactoryService,
        private readonly evseUtils: EvseUtilsService,
        private readonly dialog: MatDialog,
        private readonly toastr: ToastrService,
        private readonly datePipe: DatePipe
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.routerEventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.currentVehicleId !== undefined) {
                    const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                    if (this.currentVehicleId !== id) {
                        this.vehicleSubscription.unsubscribe();
                        this.init();
                    }
                }
            }
        });

        this.init();
    }

    ngOnDestroy(): void {
        this.vehicleSubscription.unsubscribe();
        this.routerEventSubscription?.unsubscribe();
    }

    public actions(): void {
        this.dialog.open((VehicleActionDialogComponent), {
            data: this.vehicle.getValue(),
            closeOnNavigation: true
        });
    }

    public async edit(): Promise<void> {
        const vehicle = this.vehicle.getValue();
        if(!vehicle) {
            this.toastr.warning("Kein Fahrzeug ausgewählt");
            return;
        }
        await this.vehicleService.showEditDialog(vehicle);
    }

    private async update(vehicle: Vehicle) {
        const p1 = num(vehicle.liveData.i1?.val || 0) * num(vehicle.liveData.u1?.val || 235);
        const p2 = num(vehicle.liveData.i2?.val || 0) * num(vehicle.liveData.u2?.val || 235);
        const p3 = num(vehicle.liveData.i3?.val || 0) * num(vehicle.liveData.u3?.val || 235);
        const p = vehicle.liveData.p?.val || p1 + p2 + p3;
        const pStr = p ? (num(p) / 1000).toFixed(1) + ' kW' : '--';

        this.boxes = [
            {
                icon: 'ladestation',
                description: 'Ladepunkt',
                value: (vehicle.evse ? this.evseUtils.calcEvseName(vehicle.evse) : '---'),
                link: vehicle.evse ? "/evses/evse/" + vehicle.evse.id : undefined
            }, {
                icon: 'plug',
                description: 'Datenquelle',
                value: vehicle.dataSource?.name || '---'
            }, {
                icon: 'verwaltung',
                description: 'Gebucht',
                value: await this.getNextReservation()
            }, {
                icon: 'plug_unknown',
                description: 'Ladeleistung',
                value: (pStr || '---')
            }
        ];
    }

    private async getNextReservation(): Promise<string> {
        const reservations = await this.vehicleService.getReservationsPromise(this.vehicle.getValue()?.id as number);
        if(!reservations){
            return "---";
        }
        const departureTime = reservations
            .filter(r => r.returnTime > Date.now())
            .sort((r1, r2) => r1.departureTime - r2.departureTime)[0]?.departureTime;

        return departureTime ?
            (this.datePipe.transform(departureTime) + " " + this.datePipe.transform(departureTime, "shortTime")) :
            "---";
    }

    public async delete(): Promise<void> {
        const vehicle = this.vehicle.getValue();
        if(!vehicle) {
            this.toastr.warning("Kein Fahrzeug ausgewählt");
            return;
        }
        await this.vehicleService.showDeleteDialog(vehicle, {});
        await this.router.navigateByUrl("/fleet");
    }

    public setSelectedTab(event: MatTabChangeEvent) {
        localStorageSave("VEHICLE_SELECTED_TAB", (event.index + "") as LocalStorageField["VEHICLE_SELECTED_TAB"]);
    }

    private init(): void {
        this.selectedTab = parseInt(localStorageGet("VEHICLE_SELECTED_TAB", "0"));
        this.vehicleDataTableKeys = [
            {
                display: 'Name',
                field: 'name',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Typ',
                field: 'type',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Local ID',
                field: 'localId',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Nummernschild',
                field: 'numberPlate',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Erstellt am',
                field: 'creation',
                formatter: this.formatterFactory.createDateValueFormatter()
            },
            {
                display: 'Flotte',
                field: 'fleet',
                formatter: (val: Fleet) => val ? val.name : '--'
            },
            {
                display: 'Kilometerstand',
                field: 'liveData.odometer',
                formatter: this.formatterFactory.createUnitDataValueFormatter('km')
            },
            {
                display: 'Min Ladestrom',
                field: 'confMinAmps',
                formatter: this.formatterFactory.createUnitFormatter('A')
            },
            {
                display: 'Max Ladestrom (Config)',
                field: 'confMaxAmps',
                formatter: this.formatterFactory.createUnitFormatter('A')
            },
            {
                display: 'Max Ladestrom (Fahrzeug)',
                field: 'maxAmps',
                formatter: this.formatterFactory.createUnitFormatter('A')
            },
            {
                display: 'Zeitpunkt des Anschließens',
                field: 'pluginTime',
                formatter: this.formatterFactory.createDateValueFormatter()
            },
            {
                display: 'Fahrzeugklasse',
                field: 'vehicleClass',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Max Reichweite',
                field: 'liveData.rangeFull',
                formatter: this.formatterFactory.createUnitDataValueFormatter('km')
            },
            {
                display: 'Bereitschaftsstatus',
                field: 'liveData.availState',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Fehlerstatus',
                field: 'liveData.errorState',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Signalstärke',
                field: 'liveData.rssi',
                formatter: this.formatterFactory.createUnitDataValueFormatter('dB')
            },
            {
                display: 'Ladeleistung',
                field: 'liveData.pwrText',
                formatter: this.formatterFactory.createPlainFormatter()
            },
            {
                display: 'Ladestand (User)',
                field: 'liveData.socU',
                formatter: this.formatterFactory.createUnitDataValueFormatter('%')
            },
            {
                display: 'Ladestand (Real)',
                field: 'liveData.socR',
                formatter: this.formatterFactory.createUnitDataValueFormatter('%')
            },
            {
                display: 'Ladestand Geschätzt (Real)',
                field: 'liveData.estimatedSocR',
                formatter: this.formatterFactory.createUnitDataValueFormatter('%')
            },
            {
                display: 'Ladestand Geschätzt (User)',
                field: 'liveData.estimatedSocU',
                formatter: this.formatterFactory.createUnitDataValueFormatter('%')
            },
            {
                display: 'Eingesteckt',
                field: 'liveData.plugged',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            },
            {
                display: 'Laden',
                field: 'liveData.charging',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            },
            {
                display: 'Zündungszustand',
                field: 'liveData.pwrState',
                formatter: this.formatterFactory.createPlainDataValueFormatter()
            },
            {
                display: 'P1',
                field: 'liveData.p1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            },
            {
                display: 'P2',
                field: 'liveData.p2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            },
            {
                display: 'P3',
                field: 'liveData.p3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('W')
            },
            {
                display: 'I1',
                field: 'liveData.i1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            },
            {
                display: 'I2',
                field: 'liveData.i2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            },
            {
                display: 'I3',
                field: 'liveData.i3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            },
            {
                display: 'I',
                field: 'liveData.i',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            },
            {
                display: 'U1',
                field: 'liveData.u1',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'U2',
                field: 'liveData.u2',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'U3',
                field: 'liveData.u3',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'U',
                field: 'liveData.u',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'U12',
                field: 'liveData.u12',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'V In',
                field: 'liveData.vin',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'U12 Meas',
                field: 'liveData.u12Meas',
                formatter: this.formatterFactory.createUnitDataValueFormatter('V')
            },
            {
                display: 'iCp',
                field: 'liveData.iCp',
                formatter: this.formatterFactory.createUnitDataValueFormatter('A')
            },
            {
                display: 'SOC Limit',
                field: 'liveData.socLimit',
                formatter: this.formatterFactory.createUnitDataValueFormatter('%')
            },
        ];

        this.currentVehicleId = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        this.vehicle = this.vehicleService.get(this.currentVehicleId);

        this.vehicleSubscription = this.vehicle.subscribe(vehicle => {
            if (vehicle) {
                this.update(vehicle);
            }
        });
    }
}
