import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TeslaAccount, TeslaAccountVehicleList, Vehicle} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {TeslaAccountService} from '../../service/tesla-account.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import {VehicleService} from '../../../vehicle/service/vehicle.service';

@Component({
  selector: 'app-tesla-account-table',
  templateUrl: './tesla-account-table.component.html',
  styleUrls: ['./tesla-account-table.component.scss']
})
export class TeslaAccountTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() public accounts!: TeslaAccount[];
    @Input() public vehicleLists!: { [key: string]: TeslaAccountVehicleList }

    public displayedColumns: string[] = ['id', 'name', 'vehicles', 'actions'];
    public dataSource = new MatTableDataSource<TeslaAccount>([]);
    public selection = new SelectionModel<TeslaAccount>(true, []);

    private accountVehicleLists: { [key: string]: Vehicle } = {};


    public constructor(
        private readonly teslaAccountService: TeslaAccountService,
        private readonly toastr: ToastrService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    public getVehiclesString(account: TeslaAccount): string {
        const list = this.vehicleLists[account.id + ""];
        const row = list?.list;
        if(!row || row.length === 0) {
            return "---";
        }

        return row.map(e => {
            let vName = "Nicht zugewiesen";
            if (this.accountVehicleLists[e.teslaVehicleId] !== undefined) {
                const id = this.accountVehicleLists[e.teslaVehicleId].id;
                const name = this.accountVehicleLists[e.teslaVehicleId].name;
                vName = "<a href='/fleet/vehicle/" + id + "' class='c-blue-1'>" + name + "</a>";
            }
            return e.displayName + "( " + vName + " )";
        }).join("<br>");
    }

    public async ngOnInit(): Promise<void> {
        this.dataSource = new MatTableDataSource(this.accounts);
        this.dataSource.paginator = this.paginator;

        const vehicles = await this.vehicleService.getAllPromise();
        vehicles?.forEach(vehicle => {
            if (vehicle.dataSource?.displayName === "Tesla API") {
                if (vehicle.dataSource.vendorVehicleId !== undefined) {
                    this.accountVehicleLists[vehicle.dataSource.vendorVehicleId] = vehicle;
                }
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.accounts;
    }

    public async handleEditLoad(account: TeslaAccount): Promise<void> {
        await this.teslaAccountService.showEditDialog(account);
    }
    public async handleDeleteLoad(account: TeslaAccount): Promise<void> {
        await this.teslaAccountService.showDeleteDialog(account,{});
    }

    public async refreshToken(account: TeslaAccount): Promise<void> {
        await this.teslaAccountService.updateTokenNow(account.id)
        this.toastr.info("Token wird erneuert...");
    }

    public async updateList(account: TeslaAccount): Promise<void> {
        await this.teslaAccountService.updateVehicleList(account.id)
        this.toastr.info("Fahrzeugliste wird neu geladen...");
    }
}
