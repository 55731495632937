<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="name">
            <mat-error>{{name | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Angeschlossen an:</mat-label>
            <mat-select [formControl]="powerSupplyId">
                <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
            </mat-select>
            <mat-error *ngIf="powerSupplyId.invalid">{{powerSupplyId | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Typ:</mat-label>
            <mat-select [formControl]="type">
                <mat-option [value] ="DynamicLoadType.MeterBasedDynamicLoad">Meter</mat-option>
                <mat-option [value] ="DynamicLoadType.UserDefinedLoad">Nutzer definiert</mat-option>
                <mat-option [value] ="DynamicLoadType.FormulaLoad">Formel</mat-option>
            </mat-select>
            <mat-error>{{type | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="data.type === DynamicLoadType.UserDefinedLoad" appearance="fill">
            <mat-label>Wiederholungsintervall:</mat-label>
            <mat-select [formControl]="repeatInterval" (valueChange)="updateGraphLen()">
                <mat-option [value] ="RepeatIntervalEnum.Day">Tag</mat-option>
                <mat-option [value] ="RepeatIntervalEnum.Week">Woche</mat-option>
            </mat-select>
            <mat-error>{{repeatInterval | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="data.type === DynamicLoadType.MeterBasedDynamicLoad && (meters | async) as meters" appearance="fill">
            <mat-label>Messgerät:</mat-label>
            <mat-select [formControl]="meterId">
                <mat-option *ngFor="let m of meters" [value]="m.id">{{m.name}} </mat-option>
            </mat-select>
            <mat-error>{{meterId | validationErrorFormatter}}</mat-error>
        </mat-form-field>
    </div>
    @if(data.type === DynamicLoadType.UserDefinedLoad) {
        <div *ngIf="data.repeatInterval === RepeatIntervalEnum.Week">
            <mat-tab-group>
                <mat-tab label="Montag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="0" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Dienstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="1" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Mittwoch">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="2" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Donnerstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="3" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Freitag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="4" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Samstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="5" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Sontag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="6" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="data.repeatInterval === RepeatIntervalEnum.Day">
            <app-user-defined-load-graph dayOffset="0" dayCount="1" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
            <mat-hint>Zu hohe Annahmen können tatsächliches Einsparpotenzial verringern. Zu niedrige Annahmen können zu einer Überlastung des Anschlusses führen.</mat-hint>
        </div>
        <div>
            <mat-hint>Zeitzone: {{data.zone}}</mat-hint>
        </div>
    }
    @if (data.type === DynamicLoadType.FormulaLoad) {
        <app-edit-formula [(formula)]="data.formula" [name]="data.name" (formulaChange)="formula.setValue($event)"></app-edit-formula>
        <div *ngIf="isDev" style="width:100%">
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Formel</mat-label>
                <input matInput [formControl]="formula">
                <mat-error>{{formula | validationErrorFormatter}}</mat-error>
            </mat-form-field>
        </div>
    }
    @if (data.type === DynamicLoadType.FormulaLoad || data.type === DynamicLoadType.MeterBasedDynamicLoad) {
        <hr>
        <app-help-box style="max-width: 650px" [closable]="false">
            Bei einem Ausfall des Zählers wird durch den Fallback vor Überlast geschützt.
            Es wird bei einem Ausfall eines der möglicherweise verlinkten Zähler auf den hier
            definierten Strom zurückgegriffen, um vor Sicherungsausfällen und hohen Peaks zu schützen.
        </app-help-box>
        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I1</mat-label>
                <input type="number" matInput [formControl]="fallbackI1">
                <mat-error>{{fallbackI1 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I2</mat-label>
                <input type="number" matInput [formControl]="fallbackI2">
                <mat-checkbox matSuffix class="position-absolute" style="right: 0; top: -10px" disabled="" [checked]="fallbackI1.getRawValue() == fallbackI2.getRawValue()">Sync</mat-checkbox>
                <mat-error>{{fallbackI2 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I3</mat-label>
                <input type="number" matInput [formControl]="fallbackI3">
                <mat-checkbox matSuffix class="position-absolute" style="right: 0; top: -10px" disabled="" [checked]="fallbackI1.getRawValue() == fallbackI3.getRawValue()">Sync</mat-checkbox>
                <mat-error>{{fallbackI3 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
        </div>
    }
</div>
