<h2 class="center">{{headline}}</h2>
<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

  <!-- Key Column -->
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef class="col-icon">Feld</th>
    <td mat-cell *matCellDef="let element">
        {{element.key}}<sup *ngIf="element.reference">{{element.reference}}</sup>
    </td>
  </ng-container>

  <!-- Value Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef class="col-value">Wert</th>
    <td mat-cell *matCellDef="let element" class="col-value">
        <span [matTooltip]="element.value.age" [matTooltipPosition]="'right'" *ngIf="isSimple(element.value); else complex">{{element.value}}</span>
        <ng-template #complex>
            <span [matTooltip]="element.value.age" [matTooltipPosition]="'right'">{{element.value.value}}</span><mat-icon *ngIf="element.value.showAgeIcon" [matTooltip]="element.value.age">cloud_off</mat-icon>
        </ng-template>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row
      *matRowDef="let row; columns: displayedColumns;"
      (click)="$event.stopPropagation()">
  </tr>

</table>
<span class="mat-caption" *ngIf="captions.length>0">
    <ng-container *ngFor="let caption of captions; let i = index;">
        &emsp;&emsp;{{i+1}}: {{caption.text}}<ng-container *ngIf="caption.link"><a [href]="caption.link">{{caption.link}}</a></ng-container><br>
    </ng-container>
</span>
