<div *ngIf="(meter | async) as meter; else loading" class="d-flex flex-column align-items-stretch gap-3">
    <div *ngIf='meterInfo?.type === "API"' style="min-height: 25px">
        <mat-icon class="help-icon" #helpIcon>help</mat-icon>
        <app-help-box [helpIcon]="helpIcon._elementRef" key="SMARTMETER_API_POPUP">Dieser Smart-Meter wird von IO-ELON nicht automatisiert abgefragt.<br>Die Daten können statt dessen über eine API bereitgestellt werden.<br>Siehe smartmeter_v1 in der <a href="/swaggerDoc">API-Dokumentation</a> für Details.</app-help-box>
    </div>
    <div class="headline">
        <img [src]="'/img/smartMeter/'+meter.image" alt="Bild des Energiezählers">
        <div class="boxes">
            <app-boxes [boxes]="[boxes[0]]"></app-boxes>
            <div class="spacer"></div>
            <app-boxes [boxes]="[boxes[1]]"></app-boxes>
            <div class="spacer"></div>
            <app-boxes [boxes]="[boxes[2]]"></app-boxes>
            <div class="spacer"></div>
            <app-boxes [boxes]="[boxes[3]]"></app-boxes>
        </div>
    </div>
    <div style="background: white" class="p-2">
        <div class="m-0 d-flex justify-content-end">
            <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
        </div>
        <app-meter-history-graph [meterId]="meter.id"></app-meter-history-graph>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button *ngIf='meterInfo?.type === "API" || authService.isDeveloper()' mat-raised-button color="primary" (click)="handleApiDataV1Click()">Daten per API bereitstellen</button>
    </div>
</div>

<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
