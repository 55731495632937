export function deepEqual(o1: any, o2: any): boolean {
    if(o1 === o2){
        return true;
    }
    if(typeof o1 !== typeof o2) {
        return false;
    }
    if(o1 === undefined) {
        return true;
    }
    if(o1 === null || o2 === null) {
        return false;
    }
    if(typeof o1 !== 'object' || typeof o2 !== 'object'){
        return false; // Müsste schon ganz oben true sein wenn die gleich sind
    }
    const keysO1 = Object.keys(o1);
    const keysO2 = Object.keys(o2);
    if(keysO1.length !== keysO2.length) {
        return false;
    }
    return !Object.keys(o1).some(key => (keysO2.indexOf(key) === -1) || !deepEqual(o1[key], o2[key]))
}

export function deepReplace(src: any, target: any): void {
    if(src === target){
        return;
    }
    if(typeof target !== "object") {
        throw new Error("Target has to be an array or object")
    }
    if(typeof src !== "object") {
        throw new Error("Source has to be an array or object")
    }
    if(src === null || target === null) {
        throw new Error("Unable to merge null");
    }

    const tgtObj = target as Object
    const srcObj = src as Object

    for(const tgtKey in tgtObj) {
        if(!srcObj.hasOwnProperty(tgtKey)) {
            // @ts-ignore
            delete tgtObj[tgtKey];
        }
    }

    for(const [srcKey, srcVal] of Object.entries(srcObj)) {
        // @ts-ignore
        const tgtVal = tgtObj[srcKey];
        if(typeof srcVal === "object" && typeof tgtVal === "object" && srcVal !== null && tgtVal !== null) {
            if(deepEqual(tgtVal, srcVal)) {
                continue;
            }
            if(Array.isArray(srcVal) && Array.isArray(tgtVal)) {
                deepReplaceArray(srcVal, tgtVal);
                continue;
            } else if(!Array.isArray(srcVal) && !Array.isArray(tgtVal)) {
                deepReplace(srcVal, tgtVal);
                continue;
            }
        }
        // @ts-ignore
        tgtObj[srcKey] = srcVal;
    }
}

function deepReplaceArray(src: any[], tgt: any[]) {
    if(src.every(e => e.hasOwnProperty("id") && tgt.every(e => e.hasOwnProperty("id")))) {
        sortLikeTarget(src, tgt);
    }

    const idxToDelete: number[] = []
    tgt.forEach((e, idx) => {
        if(!src.hasOwnProperty(idx)) {
            idxToDelete.push(idx);
        }
    });
    idxToDelete.reverse().forEach(idx => delete tgt[idx]);
    src.forEach((e, idx) => {
        if(tgt[idx] === e) {
            return;
        }
        if(e === null || typeof e !== "object" || typeof tgt[idx] !== "object" || tgt[idx] === null) {
            tgt[idx] = e;
            return;
        }
        if(deepEqual(tgt[idx], e)) {
            return;
        }
        if(Array.isArray(e) !== Array.isArray(tgt[idx])) {
            tgt[idx] = e;
            return;
        }
        if(Array.isArray(e)) {
            deepReplaceArray(e, tgt[idx]);
            return;
        }
        deepReplace(e, tgt[idx]);
    });
}

function sortLikeTarget(src: Array<{id: any}>, tgt: Array<{id: any}>) {
    function swap(arr: any[], idx1: number, idx2: number) {
        const x = arr[idx1];
        arr[idx1] = arr[idx2];
        arr[idx2] = x;
    }

    for(let i = 0; i < tgt.length; i++) {
        const tgtId = tgt[i].id;
        for(let j = i; j < src.length; j++) {
            const srcId = src[j].id;
            if(srcId === tgtId) {
                if(i !== j) {
                    swap(src, i, j);
                }
                break;
            }
        }
    }
}

// If value is null or undefined, defaultVal is returned, otherwise value is returned
export function defaultTo<V, D>(value: V, defaultVal: D): Exclude<Exclude<V, undefined>, null> | D {
    if(value === null || value === undefined) {
        return defaultVal;
    }
    // @ts-ignore i did the check for undefined before
    return value;
}

export function download(filename: string, text: string, mime: string = "data:text/plain;charset=utf-8") {
    const element = document.createElement('a');
    element.setAttribute('href', mime + ',' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadAsset(filename: string, path: string, mime: string = "data:text/plain;charset=utf-8") {
    const element = document.createElement('a');
    element.setAttribute('type', 'hidden');
    element.setAttribute("href", path);
    element.setAttribute('download',filename);

    document.body.appendChild(element);
    element.click();
    element.remove();

}

export function num(val: string | number | undefined | null, fallback = 0): number {
    if(typeof val === 'number') {
        return val;
    }
    if(typeof val === 'string') {
        const numVal = +val;
        if(isNaN(numVal)) {
            return fallback;
        }
        return +val;
    }
    if (val === null || val === undefined) {
        return fallback;
    }
    return fallback;
}

export function repeat<T>(val: T, count: number): T[] {
    return new Array<T>(count).fill(val);
}

export function durationBetween(duration: number) {
    const seconds = Math.floor(duration / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
        days: days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60
    };
}

export function durationBetweenHumanReadable(duration: number, showSeconds: boolean) {
    const time = durationBetween(duration);
    let txtToReturn = "";
    if (time.days > 0) {
        txtToReturn = "" + time.days + (time.days == 1? " Tag" : " Tage") + " / ";
    }

    if (time.hours > 0) {
        txtToReturn = txtToReturn + time.hours + (time.hours == 1? " Stunde" : " Stunden") + " / ";
    }

    if (time.minutes > 0) {
        txtToReturn = txtToReturn + time.minutes + (time.minutes == 1? " Minute" : " Minuten") + " / ";
    }

    if (time.seconds > 0 && showSeconds) {
        txtToReturn = txtToReturn + time.seconds + (time.seconds == 1? " Sekunde" : " Sekunden") + " / ";
    }
    if (txtToReturn === "" && duration > 0) {
        txtToReturn = "< 1 Minute";
    }else {
        txtToReturn = txtToReturn.slice(0,-3);
    }
    return txtToReturn;
}


export function durationBetweenStartAndEndHumanReadable(tstStart: number, tstEnd: number, showSeconds: boolean) {
    if (!tstEnd) {
        tstEnd = Date.now();
    }
    return durationBetweenHumanReadable(tstEnd - tstStart, showSeconds);
}


