<mat-icon class="help-icon" #helpIcon>help</mat-icon>
<div class="d-flex flex-column">

    <app-help-box [helpIcon]="helpIcon._elementRef" key="S_TEC_EDIT_DIALOG">
        Hier können S-Tec Accounts angelegt werden.<br>
        Um die Daten von S-Tec abfragen zu können, benötigt IO-ELON Zugang zu dem entsprechenden
        Account auf dem S-Tec Server.
        Hierzu muss einmal der Server im Format: <code>https://&lt;subdomain&gt;.stec-cloud.de:&lt;port&gt;</code>
        sowie das WebTicket eingegeben werden.
        Das Web Ticket hat ein Format, das in etwa so aussieht: <code>01234567-89ab-cdef-0123-456789abcdef</code>
        <br>
        Diese Informationen können bei Ihrem Ansprechpartner für das Projekt erfragt werden.
    </app-help-box>

    <div class="d-flex flex-row align-content-between">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="data.name">
            <mat-hint>Freitext zum Zuordnen</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Basis URL</mat-label>
            <input matInput type="text" [(ngModel)]="data.baseUrl">
            <mat-hint>Format: <code>https://&lt;subdomain&gt;.stec-cloud.de:&lt;port&gt;</code></mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Web Ticket</mat-label>
            <input matInput type="text" [(ngModel)]="data.webTicket">
            <mat-hint>Format: <code>01234567-89ab-cdef-0123-456789abcdef</code></mat-hint>
        </mat-form-field>
    </div>

    <ng-container *ngIf="isDev">
        <hr>
        <div>
            <h2>Dev Only</h2>
            <button mat-button mat-raised-button (click)="test()">Testen</button>

            <ng-container *ngIf="testResult">
                <h3>Prüfung</h3>
                <p>
                    <span class="green" *ngIf="testResult.gccTracingObjects || testResult.cvd">Die Zugangsdaten scheinen korrekt zu sein.</span>
                    <span class="red" *ngIf="!testResult.gccTracingObjects && !testResult.cvd">Die Zugangsdaten schienen fehlerhaft zu sein oder es liegt ein Problem in IO-ELON vor.</span>
                </p>
                <p>
                    S-Tec bietet verschiedene APIs, über die Daten abgefragt werden können. IO-ELON kann daher bei einigen Feldern verschiedene
                    Methoden verwenden. Abhängig davon, für welche die aktuellen Zugangsdaten funktionieren, wird IO-ELON das im Betrieb selbst
                    entscheiden.<br>
                    GPS ist nur verfügbar, wenn der gccTracingObjects Aufruf verfügbar ist.
                </p>


                <table class="table">
                    <thead>
                        <tr>
                            <th>Daten \\ API-Call</th>
                            <th>gccTracingObjects</th>
                            <th>gccFmsEvent</th>
                            <th>CVD</th>
                            <th>HVD</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Fahrzeugliste</th>
                            <td>
                                <span *ngIf="testResult.gccTracingObjects">
                                    <i class="fas fa-check green"></i>
                                    ({{testResult?.vehicleCountGccTracingObjects || "--"}} Fahrzeuge)
                                </span>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccTracingObjects"></i>
                            </td>
                            <td></td>
                            <td>
                                <span *ngIf="testResult.cvd">
                                    <i class="fas fa-check green"></i>
                                    ({{testResult?.vehicleCountCvd || "--"}} Fahrzeuge)
                                </span>
                                <i class="fas fa-multiply red" *ngIf="!testResult.cvd"></i>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GPS</th>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.gccTracingObjects"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccTracingObjects"></i>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Kilometerstand</th>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.gccFmsEventDistanceKilometers"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccFmsEventDistanceKilometers"></i>
                            </td>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.hvdFmsMileage"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.hvdFmsMileage"></i>
                            </td>
                        </tr>
                        <tr>
                            <th>Ladestand</th>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.gccFmsEventBatteryLevelRate"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccFmsEventBatteryLevelRate"></i>
                            </td>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.hvdBatteryLevelRate"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.hvdBatteryLevelRate"></i>
                            </td>
                        </tr>
                        <tr>
                            <th>Fahrzeug eingesteckt</th>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.gccFmsEventSsfChargingCableConnected"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccFmsEventSsfChargingCableConnected"></i>
                            </td>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.hvdChargingCableConnected"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.hvdChargingCableConnected"></i>
                            </td>
                        </tr>
                        <tr>
                            <th>Ladevorgang aktiv</th>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.gccFmsEventSsfBatteryCharging"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.gccFmsEventSsfBatteryCharging"></i>
                            </td>
                            <td></td>
                            <td>
                                <i class="fas fa-check green" *ngIf="testResult.hvdBatteryCharging"></i>
                                <i class="fas fa-multiply red" *ngIf="!testResult.hvdBatteryCharging"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </ng-container>
</div>
