import {Component, Input} from '@angular/core';
import {Basis, Evse, Load, PowerSupply, SolarSystem} from '@io-elon-common/frontend-api';
import {ToastrService} from 'ngx-toastr';
import {PowerSupplyService} from '../../../service/power-supply.service';
import {LoadUtilsService} from "../../../../../shared/helper/load-utils.service";
import {PhaseValues, SinglePhaseElectricity, ThreePhaseElectricity} from "../../../../../shared/helper/electricity";

@Component({
    selector: 'app-power-supply-tree-view-node',
    templateUrl: './power-supply-tree-view-node.component.html',
    styleUrls: ['./power-supply-tree-view-node.component.scss']
})
export class PowerSupplyTreeViewNodeComponent {

    @Input()
    public ps!: PowerSupply;

    @Input()
    public evses!: Evse[];

    @Input()
    public loads!: Load[];

    @Input()
    public solarSystems!: SolarSystem[];

    @Input()
    public basis!: Basis;

    @Input()
    public ampsMode: boolean = false;

    public constructor(
        private readonly toastr: ToastrService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly loadUtils: LoadUtilsService,
    ) {
    }

    public async handleEditPowerSupply(ps: PowerSupply): Promise<void> {
        if(!this.basis) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.powerSupplyService.showEditDialog(ps, this.basis);
    }

    public async handleDeletePowerSupply(ps: PowerSupply): Promise<void> {
        await this.powerSupplyService.showDeleteDialog(ps, {});
    }

    public solarSystemsToPowerSupply(panels: SolarSystem[], powerSupply: PowerSupply): SolarSystem[] {
        return panels.filter(p => p.powerSupplyId === powerSupply.id);
    }

    private evsePwr(evse: Evse): ThreePhaseElectricity {
        const live = evse.liveData;
        return new ThreePhaseElectricity(
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI1?.val ?? 0, live.evseU1?.val ?? 235, live.evseCosPhi1?.val ?? 1),
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI2?.val ?? 0, live.evseU2?.val ?? 235, live.evseCosPhi2?.val ?? 1),
            SinglePhaseElectricity.fromAmpsAndVoltsAndCosPhi(live.evseI3?.val ?? 0, live.evseU3?.val ?? 235, live.evseCosPhi3?.val ?? 1)
        )
    }

    private evsePlannedAmps(evse: Evse): PhaseValues {
        return PhaseValues.threePhased(evse.liveData.evseIPlanned);
    }

    private loadPhaseAmps(load: Load): PhaseValues {
        const pwr = this.loadUtils.getCurrentPwr(load)
        return new PhaseValues(
            pwr.l1 / 235,
            pwr.l2 / 235,
            pwr.l3 / 235
        );
    }
    private loadPwr(load: Load): ThreePhaseElectricity {
        const phasePowers = this.loadUtils.getCurrentPwr(load);
        return new ThreePhaseElectricity(
            SinglePhaseElectricity.fromAmpsAndVolts(phasePowers.l1 / 235, 235),
            SinglePhaseElectricity.fromAmpsAndVolts(phasePowers.l2 / 235, 235),
            SinglePhaseElectricity.fromAmpsAndVolts(phasePowers.l3 / 235, 235)
        )
    }
    public getPwr(): ThreePhaseElectricity {
        return this.evses.map(evse=>this.evsePwr(evse))
            .concat(this.loads.map(load=>this.loadPwr(load)))
            .reduce((sum, p)=>sum.add(p), ThreePhaseElectricity.NET_ZERO);
    }

    public getPlannedAmps(): PhaseValues {
        return this.evses.map(evse=>this.evsePlannedAmps(evse))
            .concat(this.loads.map(load=>this.loadPhaseAmps(load)))
            .reduce((sum, p)=>sum.add(p), PhaseValues.ZERO);
    }

    public getMaxAmps(): PhaseValues {
        return PhaseValues.threePhased(this.ps.maxAmps);
    }
}
