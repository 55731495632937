import {Component, Input, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormControl, FormGroup,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import {ExecuteBasisAdd} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {MatSelectChange} from '@angular/material/select';
import {isTypeOf, required} from '../../../../shared/components/form-control-validator/validator';
import {
    DEFAULT_RANGE_CORRECTION_FACTORS,
    RangeCorrectionFactor
} from "../../range-correction-factor";

@Component({
  selector: 'app-new-basis-dialog',
  templateUrl: './new-basis-dialog.component.html',
  styleUrls: ['./new-basis-dialog.component.scss']
})
export class NewBasisDialogComponent implements OnInit, IEditForm<ExecuteBasisAdd> {

    @Input()
    public data!: ExecuteBasisAdd;
    public rangeFactors : RangeCorrectionFactor[] =  DEFAULT_RANGE_CORRECTION_FACTORS;
    public formGroup!: FormGroup<{
        name: FormControl<string | null>
        lat: FormControl<number | null>
        lon: FormControl<number | null>
        icon: FormControl<string | null | any>
        energyPrice: FormControl<number | null>
        geoFenceRadius: FormControl<number | null>
        dynamicElectricityCostsEnabled: FormControl<boolean | null>
        konzessionsabgabe: FormControl<number | null>
        netzentgelt: FormControl<number | null>
        fleetName: FormControl<string | null>
        rangeFactorSelect: FormControl<number | null>
        rangeFactorCtrl: FormControl<number | null>
        psName: FormControl<string | null>
        psMaxAmps: FormControl<number | null>
        psGridConnectionId: FormControl<string | null>
        psPeakCalculationIntervall: FormControl<ExecuteBasisAdd.PsPeakCalculationIntervallEnum | null>
        allowContinue: FormControl<boolean | null>
    }>;

    public trackByValue<T extends {value: number | string}>(_: number, obj: T): string | number {
        return obj.value;
    }

    public constructor(
    ) {
    }

    ngOnInit(): void {
        const rangeFactorSelect = new FormControl(this.data.rangeCorrectionFactor);
        const rangeFactorCtrl = new FormControl(this.data.rangeCorrectionFactor, [
            required("Korrekturfaktor für Reichweitenschätzung muss ausfüllt sein"),
            isTypeOf("number", "Korrekturfaktor Fehlerhaft")
        ]);
        rangeFactorCtrl.valueChanges.subscribe(() => {
            this.data.rangeCorrectionFactor = rangeFactorCtrl.value!;
        })
        this.formGroup = new FormGroup({
            name: new FormControl(this.data.name, required("Name nicht ausgefüllt")),
            lat: new FormControl(this.data.lat, isTypeOf("number", "Breitengrad ist fehlerhaft")),
            lon: new FormControl(this.data.lon, isTypeOf("number", "Längengrad ist fehlerhaft")),
            icon: new FormControl(this.data.icon),
            energyPrice: new FormControl(this.data.energyPrice),
            geoFenceRadius: new FormControl(this.data.geofenceRadius),
            dynamicElectricityCostsEnabled: new FormControl(this.data.dynamicElectricityCostsEnabled),
            konzessionsabgabe: new FormControl(this.data.konzessionsabgabe),
            netzentgelt: new FormControl(this.data.netzentgelt),

            fleetName: new FormControl(this.data.fleetName, required("Name der Flotte nicht ausgefüllt")),
            rangeFactorSelect,
            rangeFactorCtrl,

            psName: new FormControl(this.data.psName, required("Name des Hauptanschlusses nicht ausgefüllt")),
            psMaxAmps: new FormControl(this.data.psMaxAmps ?? null, required("Maximaler Strom des Hauptanschlusses nicht ausgefüllt")),
            psGridConnectionId: new FormControl(this.data.psGridConnectionId ?? null),
            psPeakCalculationIntervall: new FormControl(this.data.psPeakCalculationIntervall, required("Peak Intervall nicht ausgefüllt")),
            allowContinue: new FormControl(this.data.allowContinue),
        }, {
            validators: this.checkDynamicElectricityCosts()
        });
        this.formGroup.get('rangeFactorCtrl')?.disable();
        this.formGroup.valueChanges.subscribe(change => {
            this.data.name = change.name!;
            this.data.lat = change.lat!;
            this.data.lon = change.lon!;
            this.data.energyPrice = change.energyPrice!;
            this.data.geofenceRadius = change.geoFenceRadius!;
            this.data.dynamicElectricityCostsEnabled = change.dynamicElectricityCostsEnabled!;
            this.data.konzessionsabgabe = change.konzessionsabgabe!;
            this.data.netzentgelt = change.netzentgelt!;
            this.data.fleetName = change.fleetName!;
            this.data.psName = change.psName!;
            this.data.psMaxAmps = change.psMaxAmps!;
            this.data.psGridConnectionId = change.psGridConnectionId!;
            this.data.psPeakCalculationIntervall = change.psPeakCalculationIntervall!;
            this.data.allowContinue = change.allowContinue!;
            const selectedFiles = change.icon?.files;
            if (selectedFiles && selectedFiles[0]) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    this.data.icon = reader.result as string;
                }, false);
                reader.readAsDataURL(selectedFiles[0]);
            }

            this.data.icon = change.icon;
        })
    }

    public validate(): string[] {
        this.formGroup.markAllAsTouched();
        const ret: string[] = [];
        for(const c of Object.values(this.formGroup.controls)) {
            this.addErrors(ret, c);
        }
        return ret;
    }

    public isImageAvailable(): boolean {
        return this.data.icon !== undefined && this.data.icon !== "";
    }

    changeRangeFactorSelection($event: MatSelectChange) {
        if ($event.value !== -1) {
            this.formGroup.get('rangeFactorCtrl')?.setValue($event.value);
            this.formGroup.get('rangeFactorCtrl')?.disable();
        } else {
            this.formGroup.get('rangeFactorCtrl')?.enable();
        }
    }

    private checkDynamicElectricityCosts(): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {
            const fg = ctrl as UntypedFormGroup;
            if (fg.get('dynamicElectricityCostsEnabled')?.value) {
                if (typeof fg.get('konzessionsabgabe')?.value !== 'number') {
                    return {dynamicElectricityCostsInvalid: 'Konzessionsabgabe ist fehlerhaft'};
                } else if (typeof fg.get('netzentgelt')?.value !== 'number') {
                    return {dynamicElectricityCostsInvalid: 'Netzentgelt ist fehlerhaft'};
                } else {
                    return null;
                }
            } else {
                if (typeof fg.get('energyPrice')?.value !== 'number') {
                    return {dynamicElectricityCostsInvalid: 'Strompreis ist fehlerhaft'};
                } else {
                    return null;
                }
            }
        };
    };

    private addErrors(target: string[], ctrl: AbstractControl) {
        if(ctrl.errors) {
            for(const e of Object.values(ctrl.errors)) {
                if(e) {
                    target.push(e);
                }
            }
        }
    }
}
