import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthService} from '../../guards/auth.service';
import {ELinkLocation, getMenu} from "../../../app-routing.module";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  closed: boolean = window.screen.width < 1000;

  public menu = getMenu(ELinkLocation.LEFT_MENU);

  constructor(
    private authService: AuthService
  ) { }

  public hasPermission(permissions: string[]): boolean {
      return this.authService.hasAllPermissions(...permissions);
  }
}
