import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EvseDialogData} from "../evse-log-dialog/evse-log-dialog.component";
import {Evse} from "@io-elon-common/frontend-api";
import {EvseService} from "../../service/evse.service";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-log-uploads-dialog',
    templateUrl: './evse-log-uploads-dialog.component.html',
    styleUrl: './evse-log-uploads-dialog.component.scss'
})
export class EvseLogUploadsDialogComponent {

    public requestResult: string | SafeHtml = "";
    public loading = false;
    public lastUuid = '';
    public lastUrl: any;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: BehaviorSubject<Evse>,
        public dialogRef: MatDialogRef<EvseDialogData>,
        private readonly evseService: EvseService,
        private sanitizer: DomSanitizer
    ) {
    }

    public async requestLogs() {
        this.requestResult = "";
        this.loading = true;
        try {
            const result = await this.evseService.executeAction(this.dialogData.getValue().id, {
                name: "DownloadLogOcpp",
                arguments: []
            });
            this.requestResult = this.sanitizer.bypassSecurityTrustHtml(result.result + " - " + (result.value ?? "(No details)"));
        } catch (err) {
            this.requestResult = "Fehler beim Versuch die Diagnosedaten anzufordern."
        } finally {
            this.loading = false;
        }
    }

    public url(uuid: string) {
        if(uuid === this.lastUuid) {
            return this.lastUrl;
        }
        this.lastUuid = uuid;

        const target = location.protocol + '//' + this.dialogData.value.logUploads.url + '/u/' + uuid;
        this.lastUrl = this.sanitizer.bypassSecurityTrustResourceUrl(target)
        console.log(this.lastUrl);
        return this.lastUrl;
    }
}
