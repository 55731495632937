import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Dongle, Vehicle, VehicleTeaser} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {DongleService} from '../../service/dongle.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import { MatPaginator } from '@angular/material/paginator';
import {VehicleService} from "../../../vehicle/service/vehicle.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-dongle-table',
    templateUrl: './dongle-table.component.html',
    styleUrls: ['./dongle-table.component.scss']
})
export class DongleTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public dongles!: Dongle[];

    public displayedColumns: string[] = ['id', 'dongleId', 'vehicle', 'dongleVin', 'dongleVersion', 'lastMsg','rssi', 'actions'];
    public dataSource = new MatTableDataSource<Dongle>([]);
    public vehicles?: BehaviorSubject<Vehicle[] | undefined>

    constructor(
        private readonly dongleService: DongleService,
        private readonly vehicleService: VehicleService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.dongles);
        this.dataSource.paginator = this.paginator;
        this.vehicles = this.vehicleService.getAll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.dongles;
    }

    public async edit(dongle: Dongle): Promise<void> {
        await this.dongleService.showEditDialog(dongle);
    }

    public async delete(dongle: Dongle): Promise<void> {
        await this.dongleService.showDeleteDialog(dongle, {});
    }

    public getSignal(vehicle: VehicleTeaser, vehicles: Vehicle[]) {
        return vehicles.find(v => v.id === vehicle.id)?.liveData.rssi;
    }

    public trackBy(idx: number, item: Dongle) {
        return item.id;
    }
}
