<div class="help-box d-flex gap-2" *ngIf="!closed">
    <div class="flex-grow-0 align-self-center">
        <i class="far fa-circle-question fa-2x"></i>
    </div>
    <div class="flex-grow-1">
        <ng-content></ng-content>
    </div>
    <div class="flex-grow-0" *ngIf="closable">
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>
</div>
