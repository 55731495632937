import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {AlgoV2Config, ExecuteFleetUpdate} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AlgoV2ConfigService} from '../../../algo-configs/service/algoV2Config.service';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {isTypeOf, required} from '../../../../shared/components/form-control-validator/validator';
import {
    DEFAULT_RANGE_CORRECTION_FACTORS,
    RangeCorrectionFactor
} from "../../range-correction-factor";

@Component({
    selector: 'app-edit-fleet-dialog',
    templateUrl: './edit-fleet-dialog.component.html',
    styleUrls: ['./edit-fleet-dialog.component.scss']
})
export class EditFleetDialogComponent implements IEditForm<ExecuteFleetUpdate>, OnInit {

    @Input()
    public data!: ExecuteFleetUpdate;
    public formGroup !: UntypedFormGroup;
    public algoConfigs!: BehaviorSubject<AlgoV2Config[] | undefined>;
    public rangeFactors: RangeCorrectionFactor[]  =  DEFAULT_RANGE_CORRECTION_FACTORS;

    public trackByValue<T extends { value: number | string }>(idx: number, obj: T): string | number {
        return obj.value;
    }

    public constructor(
        private readonly algoV2ConfigService: AlgoV2ConfigService
    ) {
    }

    ngOnInit(): void {
        if (this.data.algoV2ConfigId === null) {
            this.data.algoV2ConfigId = -1;
        }
        const nameCtrl = new UntypedFormControl(this.data.name, required('Name darf nicht leer sein'));
        nameCtrl.valueChanges.subscribe(() => {
            this.data.name = nameCtrl.value;
        })
        const factorSelectCtrl = new UntypedFormControl(this.data.rangeCorrectionFactor);
        const rangeFactorCtrl = new UntypedFormControl(this.data.rangeCorrectionFactor,
            [
                required("Korrekturfaktor für Reichweitenschätzung muss ausfüllt sein"),
                isTypeOf("number", "Korrekturfaktor Fehlerhaft")
            ]);
        rangeFactorCtrl.valueChanges.subscribe(() => {
            this.data.rangeCorrectionFactor = rangeFactorCtrl.value;
        })
        const algoCtrl = new UntypedFormControl(this.data.algoV2ConfigId);
        algoCtrl.valueChanges.subscribe(() => {
            this.data.algoV2ConfigId = algoCtrl.value;
        })
        const rfidTrainingModeCtrl = new UntypedFormControl(this.data.rfidTrainingMode);
        rfidTrainingModeCtrl.valueChanges.subscribe(() => {
            this.data.rfidTrainingMode = rfidTrainingModeCtrl.value;
        })
        this.formGroup = new UntypedFormGroup({
            nameCtrl,
            rangeFactorCtrl,
            factorSelectCtrl,
            algoCtrl,
            rfidTrainingModeCtrl
        });
        this.algoConfigs = this.algoV2ConfigService.getAll();
        this.checkIfDefaultIsSelected(this.data.rangeCorrectionFactor ?? 0.7);
    }

    public validate(): string[] {
        this.formGroup.markAllAsTouched();
        const ret: string[] = [];
        for (const c of Object.values(this.formGroup.controls)) {
            this.addErrors(ret, c);
        }
        return ret;
    }

    changeRangeFactorSelection($event: MatSelectChange) {
        if ($event.value !== -1) {
            this.formGroup.get('rangeFactorCtrl')?.setValue($event.value);
            this.formGroup.get('rangeFactorCtrl')?.disable();
        } else {
            this.formGroup.get('rangeFactorCtrl')?.enable();
        }
    }

    checkIfDefaultIsSelected(value: number) {
        if (this.rangeFactors.filter((rangeFactorValue: RangeCorrectionFactor) => rangeFactorValue.value === value).length > 0) {
            this.formGroup.get('rangeFactorCtrl')?.disable();
        } else {
            // set Range Factor to User Defined if factor is no Default
            this.formGroup.get('factorSelectCtrl')?.setValue(-1);
            this.formGroup.get('rangeFactorCtrl')?.enable();
        }
    }

    private addErrors(target: string[], ctrl: AbstractControl) {
        if (ctrl.errors) {
            for (const e of Object.values(ctrl.errors)) {
                if (e) {
                    target.push(e);
                }
            }
        }
    }
}
