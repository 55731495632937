import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api-handlers/api.service';
import {ExecuteTeslaApiDatasourceAddOrUpdate, TeslaApiDatasource} from '@io-elon-common/frontend-api';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditTeslaApiDatasourceDialogComponent} from '../dialogs/edit-tesla-api-dialog/edit-tesla-api-datasource-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

@Injectable({
    providedIn: 'root'
})
export class TeslaApiDatasourceService extends DialogHandler<TeslaApiDatasource, ExecuteTeslaApiDatasourceAddOrUpdate, ExecuteTeslaApiDatasourceAddOrUpdate, {}, void, void> {
    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, 'TeslaApiDatasource', toastr, dialog, dialogService, POLL_INTERVALS.teslaDatasource);
    }

    public async pollNow(id: number, showAlerts = true): Promise<void> {
        await this.apiService.pollTeslaVehicleNow(showAlerts, id, undefined, undefined,  ApiHandler.customerId, {}).toPromise();
    }

    public async wakeUp(id: number, showAlerts = true): Promise<void> {
        await this.apiService.wakeUpTeslaVehicle(showAlerts, id, undefined, undefined, ApiHandler.customerId, {}).toPromise();
    }

    protected getEditConfig(teslaApiDatasource: TeslaApiDatasource): TDialogOptions<ExecuteTeslaApiDatasourceAddOrUpdate, EditTeslaApiDatasourceDialogComponent> {
        return {
            headline: "Tesla Fahrzeug bearbeiten",
            component: EditTeslaApiDatasourceDialogComponent,
            executeCallback: editResult => this.update(teslaApiDatasource.id, editResult),
            editElement: {
                teslaVehicleId: teslaApiDatasource.teslaVehicleId,
                vehicleId: teslaApiDatasource.vehicle.id,
                teslaAccountId: teslaApiDatasource.teslaAccountId
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteTeslaApiDatasourceAddOrUpdate, EditTeslaApiDatasourceDialogComponent> {
        return {
            headline: "Tesla Account anlegen",
            component: EditTeslaApiDatasourceDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                teslaVehicleId: 0,
                vehicleId: 0,
                teslaAccountId: -1
            }
        };
    }
}
