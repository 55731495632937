<div class="main-dev d-flex flex-column gap-3">
    <mat-form-field appearance="fill" class="input-group">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="input-group">
        <mat-label>OAuth Refresh Token</mat-label>
        <input matInput [(ngModel)]="data.oAuthRefreshToken">
    </mat-form-field>

    <mat-form-field appearance="fill" class="input-group">
        <mat-label>Owner API Token</mat-label>
        <input matInput [(ngModel)]="data.ownerApiToken">
    </mat-form-field>
</div>
