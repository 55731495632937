import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Evse, Load, Meter, PhaseRotation} from "@io-elon-common/frontend-api";
import {EvseService} from "../../../../evse/service/evse.service";
import {LoadService} from "../../../service/load.service";
import {MeterService} from "../../../../meter/service/meter.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FleetService} from '../../../../vehicle/service/fleet.service';

type TermType = "E" | "M" | "C" | "L"

interface EvseTermArgs {
    id: number
}

interface MeterTermArgs {
    id: number
    rotation: PhaseRotation
}

interface LoadTermArgs {
    id: number
    rotation: PhaseRotation
}

interface ConstTermArgs {
    p1: number
    p2: number,
    p3: number
}


type TermArgs = EvseTermArgs | MeterTermArgs | LoadTermArgs | ConstTermArgs
type TermArgsUnion = EvseTermArgs & MeterTermArgs & LoadTermArgs & ConstTermArgs

type Operation = "+" | "-";

interface Term {
    operation: Operation
    type: TermType
    args: TermArgs
}

@Component({
    selector: 'app-edit-formula',
    templateUrl: './edit-formula.component.html',
    styleUrls: ['./edit-formula.component.scss']
})
export class EditFormulaComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public name!: string;
    @Input()
    public formula!: string
    @Output()
    public formulaChange: EventEmitter<string> = new EventEmitter<string>();

    public terms: Term[] = [];

    public editTerm: Term & {args: TermArgsUnion};
    public editIdx: number | null = null;
    public removeIdx: number = -1;

    public parseError = false;
    public editorHeight: 0 | "62px" = 0;

    public allEvses: BehaviorSubject<Evse[] | undefined>;
    public allLoads: BehaviorSubject<Load[] | undefined>;
    public allMeters: BehaviorSubject<Meter[] | undefined>;
    private evseSubscription: Subscription;
    private loadSubscription: Subscription;
    private meterSubscription: Subscription;
    private selectedFleetSubsciption!: Subscription;
    private fleetId!: number;
    private basisId?: number;

    constructor(
        private readonly evseService: EvseService,
        private readonly loadService: LoadService,
        private readonly meterService: MeterService,
        private readonly ngZone: NgZone,
        private readonly fleetService: FleetService
    ) {
        this.editTerm = {
            operation: "+",
            type: "C",
            args: {
                id: -1,
                p1: 0,
                p2: 0,
                p3: 0,
                rotation: "NO_ROTATION"
            }
        }

        this.allEvses = evseService.getAll();
        this.allLoads = loadService.getAll();
        this.allMeters = meterService.getAll();

        this.evseSubscription = this.allEvses.subscribe();
        this.loadSubscription = this.allLoads.subscribe();
        this.meterSubscription = this.allMeters.subscribe();
    }

    ngOnDestroy() {
        this.evseSubscription.unsubscribe();
        this.loadSubscription.unsubscribe();
        this.meterSubscription.unsubscribe();
        this.selectedFleetSubsciption.unsubscribe();
    }

    async ngOnInit(): Promise<void> {
        this.parse();
        this.selectedFleetSubsciption = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
                this.fleetService.getPromise(id).then(f => {
                    return this.basisId = f.base.id;
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.parse();
        let edit = this.editIdx;
        if(edit !== null && edit !== -1) {
            this.editIdx = null;
            this.edit(edit);
        }
    }

    private parse(): void {
        let str = this.formula;
        try {
            this.terms = [];
            while(str.length > 0) {
                let operation: Operation = str.charAt(0) as Operation;
                let type: TermType = str.charAt(2) as TermType;
                str = str.substr(4);
                let args: TermArgs;
                switch (type) {
                    case "C": {
                        const argRaw = /^([^ ]+) ([^ ]+) ([^ ]+)/.exec(str)
                        if(argRaw == null) {
                            throw new Error("Unable to parse constant.")
                        }
                        args = {
                            p1: +argRaw[1],
                            p2: +argRaw[2],
                            p3: +argRaw[3]
                        }
                        str = str.substr(argRaw[0].length)
                    } break;
                    case "E": {
                        const argRaw = /^([^ ]+)/.exec(str)
                        if(argRaw == null) {
                            throw new Error("Unable to parse constant evse.")
                        }
                        args = {
                            id: +argRaw[1]
                        }
                        str = str.substr(argRaw[0].length)
                    } break;
                    case "L":
                    case "M": {
                        const argRaw = /^([^ ]+) ([^ ]+)/.exec(str)
                        if(argRaw == null) {
                            throw new Error("Unable to parse constant Load/Meter.")
                        }
                        args = {
                            id: +argRaw[1],
                            rotation: argRaw[2] as PhaseRotation
                        }
                        str = str.substr(argRaw[0].length)
                    } break;
                    default: throw new Error("Unknown Term Type: " + this.formula);
                }
                this.terms.push({
                    operation,
                    type,
                    args
                });
                if(str.length > 0) {
                    str = str.substr(1);
                }
            }
            this.parseError = false;
        } catch (exc) {
            this.parseError = true;
            console.error(exc);
        }
    }

    public remove1(idx: number): void {
        this.removeIdx = idx;
        setTimeout(() => this.ngZone.run( () => this.removeIdx = -1), 2000);
    }

    public remove2(idx: number): void {
        if(this.editIdx !== null) {
            if(idx < this.editIdx) {
                this.editIdx--;
            } else if(idx === this.editIdx) {
                this.editIdx = null;
            }
        }
        this.terms.splice(idx, 1);
        this.removeIdx = -1;
        this.emitTerms();
    }

    public edit(idx: number): void {
        if(this.editIdx === idx) {
            this.editorHeight = 0;
            setTimeout(() => this.ngZone.run( () => this.editIdx = null), 500);
            return;
        }

        this.editIdx = idx;
        if(idx === -1) {
            this.editorHeight = "62px"
            this.editTerm = {
                operation: "+",
                type: "C",
                args: {
                    id: -1,
                    p1: 0,
                    p2: 0,
                    p3: 0,
                    rotation: "NO_ROTATION"
                }
            }
        } else {
            setTimeout(() => this.ngZone.run( () => this.editorHeight = "62px"), 10);
            let args: TermArgsUnion;

            switch(this.terms[idx].type) {
                case "E":
                    args = {
                        id: (this.terms[idx].args as EvseTermArgs).id,
                        p1: 0,
                        p2: 0,
                        p3: 0,
                        rotation: "NO_ROTATION"
                    }
                    break;
                case "L":
                    args = {
                        id: (this.terms[idx].args as LoadTermArgs).id,
                        p1: 0,
                        p2: 0,
                        p3: 0,
                        rotation: (this.terms[idx].args as LoadTermArgs).rotation
                    }
                    break;
                case "M":
                    args = {
                        id: (this.terms[idx].args as MeterTermArgs).id,
                        p1: 0,
                        p2: 0,
                        p3: 0,
                        rotation: (this.terms[idx].args as MeterTermArgs).rotation
                    }
                    break;
                case "C":
                    args = {
                        id: -1,
                        p1: (this.terms[idx].args as ConstTermArgs).p1,
                        p2: (this.terms[idx].args as ConstTermArgs).p2,
                        p3: (this.terms[idx].args as ConstTermArgs).p3,
                        rotation: "NO_ROTATION"
                    }
                    break;
                default:
                    throw new Error("Unknown Type: " + this.terms[idx].type);
            }

            this.editTerm = {
                operation: this.terms[idx].operation,
                type: this.terms[idx].type,
                args
            }
            this.terms[idx] = this.editTerm
        }
    }

    public commit(): void {
        if(this.editIdx == null) {
            throw new Error("Unable to commit change, when editor is not visible. (editIdx === null)")
        }
        if(this.editIdx == -1) {
            this.terms.push(this.editTerm);
            this.editIdx = null;
        } else {
            this.terms[this.editIdx] = this.editTerm;
            setTimeout(() => this.ngZone.run( () => this.editIdx = null), 500);
        }

        this.editTerm = {
            operation: "+",
            type: "C",
            args: {
                id: -1,
                p1: 0,
                p2: 0,
                p3: 0,
                rotation: "NO_ROTATION"
            }
        }
        this.editorHeight = 0;
        this.emitTerms();
        this.parse();
    }

    public emitTerms(): void {
        const ret = this.terms.map(t => {
            let str = t.operation;
            str += " "
            str += t.type;
            str += " "
            switch (t.type) {
                case "C":
                    str += (t.args as ConstTermArgs).p1;
                    str += " ";
                    str += (t.args as ConstTermArgs).p2;
                    str += " ";
                    str += (t.args as ConstTermArgs).p3;
                    break;
                case "M":
                    str += (t.args as MeterTermArgs).id;
                    str += " ";
                    str += (t.args as MeterTermArgs).rotation;
                    break;
                case "L":
                    str += (t.args as LoadTermArgs).id;
                    str += " ";
                    str += (t.args as LoadTermArgs).rotation;
                    break;
                case "E":
                    str += (t.args as LoadTermArgs).id;
                    break;
                default:
                    throw new Error("Unknown type: " + t.type);
            }
            return str;
        }).join(" ");

        this.formula = ret;
        this.formulaChange.emit(ret);
    }

    public rotationImage(rotation: PhaseRotation): string {
        switch (rotation) {
            case 'NO_ROTATION':
                return "/assets/img/phase_rotation/no_rotate.png"
            case 'LEFT':
                return "/assets/img/phase_rotation/left.png"
            case 'RIGHT':
                return "/assets/img/phase_rotation/right.png"
            case 'SWITCH_1_2':
                return "/assets/img/phase_rotation/switch12.png"
            case 'SWITCH_1_3':
                return "/assets/img/phase_rotation/switch13.png"
            case 'SWITCH_2_3':
                return "/assets/img/phase_rotation/switch23.png"
            default:
                return "/assets/img/phase_rotation/empty.png"
        }
    }

    public meterName(meterId: number): string {
        return this.allMeters.value?.find(m => m.id === meterId)?.name || ("ID[" + meterId + "]");
    }

    public loadName(loadId: number): string {
        return this.allLoads.value?.find(m => m.id === loadId)?.name || ("ID[" + loadId + "]");
    }

    public resetId() {
        this.editTerm.args.id = -1;
    }

    public filterAndSortEvses(evses: Evse[]): Evse[] {
        return evses.filter(e => e.basis.id === this.basisId)
                .sort((a, b) => a.name.localeCompare(b.name));
    }

    public filterAndSortMeter(meters: Meter[]): Meter[] {
        return meters.filter(m => m.basis !== undefined && m.basis.id === this.basisId)
        // @ts-ignore
        .sort((a, b) => a.name.localeCompare(b.name));
    }
}
