<div class="input-area">
    <mat-form-field appearance="fill" class="selection-field">
        <mat-label>EVSEs</mat-label>
        <mat-select [(ngModel)]="selectedEvses" multiple>
            <mat-select-trigger>
                        <span>
                            {{formatSelection(selectedEvses)}}
                        </span>
            </mat-select-trigger>
            <mat-option *ngFor="let evse of allEvses" [value]="evse">
                {{evse.id}} - {{evse.name}}
            </mat-option>
        </mat-select>
        <mat-hint><span class="button" (click)="selectAllEvses()">Alle</span> <span class="button" (click)="selectedEvses=[]">Keine</span></mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill" class="selection-field">
        <mat-label>EVSE - Keys</mat-label>
        <mat-select [(ngModel)]="evseKeys" multiple>
            <mat-select-trigger>
                        <span>
                            {{formatSelection(evseKeys)}}
                        </span>
            </mat-select-trigger>
            <mat-option *ngFor="let key of evseKeysAll" [value]="key">
                {{key.name}}[{{key.unit}}]
            </mat-option>
        </mat-select>
        <mat-hint><span class="button" (click)="selectAllEvseKeys()">Alle</span> <span class="button" (click)="evseKeys=[]">Keine</span></mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill" class="selection-field">
        <mat-label>Fahrzeuge</mat-label>
        <mat-select [(ngModel)]="selectedVehicles" multiple>
            <mat-select-trigger>
                        <span>
                            {{formatSelection(selectedVehicles)}}
                        </span>
            </mat-select-trigger>
            <mat-option *ngFor="let vehicle of allVehicles" [value]="vehicle">
                {{vehicle.id}} - {{vehicle.name}}
            </mat-option>
        </mat-select>
        <mat-hint><span class="button" (click)="selectAllVehicles()">Alle</span> <span class="button" (click)="selectedVehicles=[]">Keine</span></mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill" class="selection-field">
        <mat-label>Fahrzeuge - Keys</mat-label>
        <mat-select [(ngModel)]="vehicleKeys" multiple>
            <mat-select-trigger>
                        <span>
                            {{formatSelection(vehicleKeys)}}
                        </span>
            </mat-select-trigger>
            <mat-option *ngFor="let key of vehicleKeysAll" [value]="key">
                {{key.name}}[{{key.unit}}]
            </mat-option>
        </mat-select>
        <mat-hint><span class="button" (click)="selectAllVehicleKeys()">Alle</span> <span class="button" (click)="vehicleKeys=[]">Keine</span></mat-hint>
    </mat-form-field>

    <div class="mem">
        <button matTooltip="Auswahl Speichern Slot 1" mat-raised-button [color]="'primary'" (click)="mem(1)">M1</button>
        <button matTooltip="Auswahl Speichern Slot 2" mat-raised-button [color]="'primary'" (click)="mem(2)">M2</button>
        <button matTooltip="Auswahl Speichern Slot 3" mat-raised-button [color]="'primary'" (click)="mem(3)">M3</button><br>
        <button matTooltip="Auswahl Laden Slot 1" mat-raised-button [color]="'primary'" (click)="load(1)">R1</button>
        <button matTooltip="Auswahl Laden Slot 2" mat-raised-button [color]="'primary'" (click)="load(2)">R2</button>
        <button matTooltip="Auswahl Laden Slot 3" mat-raised-button [color]="'primary'" (click)="load(3)">R3</button>
    </div>

    <div class="load-wrapper">
        <mat-checkbox [(ngModel)]="autoUpdate" (change)="updateTimer()" (click)="autoUpdateSpeed=1000">Auto Update</mat-checkbox>
        <mat-slider *ngIf="autoUpdate" [(ngModel)]="autoUpdateSpeed" [min]="200" [max]="5000" matSliderThumb [displayWith]="formatDelay" [step]="200" #ngSlider><input matSliderThumb (change)="updateTimer()" #ngSliderThumb="matSliderThumb" /></mat-slider>
        <br>
        <button mat-raised-button [disabled]="loading" [color]="'primary'" (click)="update()">Laden</button>
        <span *ngIf="lastResponseTime" class="time">{{lastResponseTime | age}}</span>
    </div>
</div>
<hr>
<div *ngIf="liveData">
    <table>
        <ng-container *ngIf="evseKeys.length > 0 && evseData && (evseData | keyvalue).length > 0">
            <tr>
                <th>Evse</th>
                <th *ngFor="let k of evseKeys">{{k.name}}</th>
            </tr>
            <tr *ngFor="let dat of (evseData | keyvalue)">
                <td>
                    <i *ngIf="editEvse!==dat.key" class="fas fa-edit" matTooltip="Es sollten nur die Spalten ausgewählt werden, die auch geändert werden sollen!" (click)="editEvse=dat.key; editVehicle=''"></i>
                    <i *ngIf="editEvse===dat.key" class="fas fa-times" (click)="editEvse=''; editVehicle=''"></i>
                    <i *ngIf="editEvse===dat.key" class="fas fa-save" [matTooltip]="'Speichern als DataRow mit Events'" (click)="save('DataRow')"></i>
                    <i *ngIf="editEvse===dat.key" class="fa-regular fa-save" [matTooltip]="'Direkt schreiben, ohne Events. Sollte nicht benutzt werden außer es gibt einen sehr guten Grund'" (click)="save('WriteToCache')"></i>
                    <ng-container *ngIf="editEvse===dat.key">
                        <br>
                    </ng-container>
                    {{getEvseName(dat.key)}}
                </td>
                <td *ngFor="let k of evseKeys">
                    <ng-container *ngIf="editEvse!==dat.key">
                        <span class="value">{{dat.value[k.name].val}} {{k.unit}}</span>
                        <span *ngIf="dat.value[k.name].tst" class="time">{{dat.value[k.name].source}} {{dat.value[k.name].tst | age}}</span>
                    </ng-container>
                    <ng-container *ngIf="editEvse===dat.key">
                        <input [(ngModel)]="dat.value[k.name].val">
                    </ng-container>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="vehicleKeys.length > 0 && vehicleData && (vehicleData | keyvalue).length > 0">
            <tr>
                <th>Fahrzeug</th>
                <th *ngFor="let k of vehicleKeys">{{k.name}}</th>
            </tr>
            <tr *ngFor="let dat of (vehicleData | keyvalue)">
                <td>
                    <i *ngIf="editVehicle!==dat.key" class="fas fa-edit" matTooltip="Es sollten nur die Spalten ausgewählt werden, die auch geändert werden sollen!" (click)="editVehicle=dat.key; editEvse=''"></i>
                    <i *ngIf="editVehicle===dat.key" class="fas fa-times" (click)="editVehicle=''; editEvse=''"></i>
                    <i *ngIf="editVehicle===dat.key" class="fas fa-save" [matTooltip]="'Speichern als DataRow mit Events'" (click)="save('DataRow')"></i>
                    <i *ngIf="editVehicle===dat.key" class="fa-regular fa-save" [matTooltip]="'Direkt schreiben, ohne Events. Sollte nicht benutzt werden außer es gibt einen sehr guten Grund'" (click)="save('WriteToCache')"></i>
                    <ng-container *ngIf="editVehicle===dat.key">
                        <br>
                    </ng-container>
                    {{getVehicleName(dat.key)}}
                </td>
                <td *ngFor="let k of vehicleKeys">
                    <ng-container *ngIf="editVehicle!==dat.key">
                        <span class="value">{{dat.value[k.name].val}} {{k.unit}}</span>
                        <span *ngIf="dat.value[k.name].tst" class="time">{{dat.value[k.name].source}} {{dat.value[k.name].tst | age}}</span>
                    </ng-container>
                    <ng-container *ngIf="editVehicle===dat.key">
                        <input [(ngModel)]="dat.value[k.name].val">
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
