<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="data.name">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Angeschlossen an:</mat-label>
            <mat-select [(value)]="data.powerSupplyId">
                <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Messgerät:</mat-label>
        <mat-select [(value)]="data.meterId">
            <mat-option [value]=undefined>-- Kein Messgerät --</mat-option>
            <mat-option *ngFor="let m of possibleMeters" [value]="m.id">{{m.name}} </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <h4>Angeschlossen an:</h4>
        <mat-checkbox [(ngModel)]="data.l1">L1</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.l2">L2</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.l3">L3</mat-checkbox>
    </div>
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Solar-Daten-Provider</mat-label>
            <mat-select [(value)]="data.solarPredictionProvider">
                <mat-option value="NAECO_BLUE">Naeco Blue</mat-option>
                <mat-option value="ENERCAST">Enercast</mat-option>
                <mat-option value="FORECAST_SOLAR">Forecast.Solar</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="data.solarPredictionProvider==='NAECO_BLUE'">
            <mat-label>Naeco Blue Dateiname</mat-label>
            <input matInput type="text" [(ngModel)]="data.naecoBlueFileName">
            <mat-hint>Es sollte ungefähr so aussehen: "NB003_SLR"</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="data.solarPredictionProvider==='FORECAST_SOLAR'">
            <mat-label>Forecast.Solar API key</mat-label>
            <input matInput type="text" [(ngModel)]="data.forecastSolarKey">
            <mat-hint></mat-hint>
        </mat-form-field>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
