import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {localStorageGet, localStorageSave} from "../../helper/typed-local-storage";

@Component({
    selector: 'app-help-box',
    templateUrl: './help-box.component.html',
    styleUrls: ['./help-box.component.scss']
})
export class HelpBoxComponent implements OnInit, OnDestroy {

    @Input()
    public closable = true;

    @Input()
    public key?: string
    @Input()
    public helpIcon?: ElementRef
    public helpIconEventListenerRemoval?: () => void;

    public closed = false;

    public constructor(
        private readonly renderer: Renderer2
    ) {
    }

    public ngOnInit(): void {
        if(this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet("CLOSED_HELP_TEXTS", "[]"));
            if(closedTexts.indexOf(this.key) !== -1){
                this.closed = true;
            }
        }
        if(this.helpIcon) {
            this.helpIconEventListenerRemoval = this.renderer.listen(this.helpIcon.nativeElement, "click", () => this.open());
        }
    }

    ngOnDestroy(): void {
        if(this.helpIconEventListenerRemoval) {
            this.helpIconEventListenerRemoval();
        }
    }

    public open() {
        if(this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet("CLOSED_HELP_TEXTS", "[]"));
            const idx = closedTexts.indexOf(this.key);
            if(idx !== -1){
                closedTexts.splice(idx, 1);
                localStorageSave("CLOSED_HELP_TEXTS", JSON.stringify(closedTexts));
            }
        }
        this.closed = false;
    }

    public close(){
        if(this.key) {
            const closedTexts: string[] = JSON.parse(localStorageGet("CLOSED_HELP_TEXTS", "[]"));
            if(closedTexts.indexOf(this.key) === -1){
                closedTexts.push(this.key);
                localStorageSave("CLOSED_HELP_TEXTS", JSON.stringify(closedTexts));
            }
        }
        this.closed = true;
    }

}
