import { Component } from '@angular/core';
import {
    ExportFieldsEvseData,
    ExportFieldsEvseDescription, ExportFieldsSolarData, ExportFieldsSolarDescription,
    ExportFieldsVehicleData, ExportFieldsVehicleDescription,
    ExportType
} from "@io-elon-common/frontend-api";
import {KeyValue} from "@angular/common";

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrl: './export.component.scss'
})
export class ExportComponent {

    public exportOptions: Record<ExportType, {name: string, selected: boolean}[]> = {
        EVSE_DATA: Object.values(ExportFieldsEvseData).map(name => { return {name, selected: true}}),
        EVSE_DESCRIPTION: Object.values(ExportFieldsEvseDescription).map(name => { return {name, selected: true}}),
        VEHICLE_DATA: Object.values(ExportFieldsVehicleData).map(name => { return {name, selected: true}}),
        VEHICLE_DESCRIPTION: Object.values(ExportFieldsVehicleDescription).map(name => { return {name, selected: true}}),
        SOLAR_DATA: Object.values(ExportFieldsSolarData).map(name => { return {name, selected: true}}),
        SOLAR_DESCRIPTION: Object.values(ExportFieldsSolarDescription).map(name => { return {name, selected: true}}),
    };

    get instanceName(): string {
        return location.hostname
    }


    buildLink(e: KeyValue<string, { name: string; selected: boolean }[]>) {

        const cols = e.value.filter(v => v.selected).map(v => v.name).join(".");

        return location.protocol + "//" + location.host + "/api/export?type=" + e.key + "&cols=" + cols;
    }

    all(e: KeyValue<string, { name: string; selected: boolean }[]>) {
        e.value.forEach(v => v.selected = true);
    }

    none(e: KeyValue<string, { name: string; selected: boolean }[]>) {
        e.value.forEach(v => v.selected = false);
    }
}
