import { AbstractTableComponent } from '../AbstractTableComponent';
import { Injectable, Input, ViewChild } from '@angular/core';
import { ChargingSession, ChargingSessionList, Evse, Vehicle } from '@io-elon-common/frontend-api';
import { download } from '../../../helper/util-functions';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiService } from '../../../../services/api-handlers/api.service';
import { VehicleService } from '../../../../modules/vehicle/service/vehicle.service';
import { EvseService } from '../../../../modules/evse/service/evse.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { DurationPipe } from '../../../helper/duration.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { SystemService } from 'src/app/services/api-handlers/system.service';
import {ChargingSessionsService} from "../../../../modules/charging-session/service/chargingSession.service";
import {AuthService} from "../../../guards/auth.service";

@Injectable()
export abstract class ChargingsTable extends AbstractTableComponent {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    private static EMPTY = Promise.resolve(undefined);

    @Input() vehicleId!: number;

    public abstract readonly displayedColumns: string[];
    protected abstract readonly apiService: ApiService;
    protected abstract readonly vehicleService: VehicleService;
    protected abstract readonly evseService: EvseService;
    protected abstract readonly toastrService: ToastrService;
    protected abstract readonly dialog: MatDialog;
    protected abstract readonly durationPipe: DurationPipe;
    protected abstract readonly chargingService: ChargingSessionsService;
    protected abstract readonly systemService: SystemService;
    protected abstract readonly authService: AuthService

    chargingProcesses!: ChargingSession[];
    price!: string;

    dataSource = new MatTableDataSource<ChargingSession>([]);
    selection = new SelectionModel<ChargingSession>(true, []);
    selectedIndex = 0;
    finished = false;
    error: null | string = null;

    evses: Promise<Evse | undefined>[] = [];
    vehicles: Promise<Vehicle | undefined>[] = [];
    displayStopChargeButton: boolean = false;
    downloads: {[key: number]: boolean} = {};

    protected constructor(
    ) {
        super();
    }

    protected abstract getChargings(): Promise<ChargingSessionList>;
    public abstract isAllowContinue(cs: ChargingSession): Promise<boolean>;


    public showMetaDownload(): boolean {
        return this.authService.isAdmin();
    }

    public async refresh(): Promise<void> {
        try {
            this.dataSource = new MatTableDataSource([] as ChargingSession[]);
            this.dataSource.paginator = this.paginator;
            const result = await this.getChargings()

            result.list.sort((cp1: ChargingSession, cp2: ChargingSession) => cp2.tstStart - cp1.tstStart);
            this.price = (result.price * 100).toFixed(0) + " ct/kWh";
            this.chargingProcesses = result.list;
            this.dataSource.data = this.chargingProcesses;

        } catch (e) {
            console.error(e)
            this.error = 'Fehler beim Laden';
        } finally {
            this.finished = true;
        }
    }

    public async onInit(): Promise<void> {
        try {
            const result = this.getChargings()

            result.then(csList => {
                csList.list.sort((cp1: ChargingSession, cp2: ChargingSession) => cp2.tstStart - cp1.tstStart);
                this.price = (csList.price * 100).toFixed(0) + " ct/kWh";
                this.chargingProcesses = csList.list;
                this.dataSource.data = this.chargingProcesses;
            }).catch(err => {
                console.error(err)
                this.error = "Fehler beim Laden"
            });

            this.dataSource = new MatTableDataSource([] as ChargingSession[]);
            this.dataSource.paginator = this.paginator;
        } catch (e) {
            this.error = 'Fehler beim Laden';
        } finally {
            this.finished = true;
        }
        this.displayStopChargeButton = (await this.systemService.getSystemInfo()).displayStopChargeButton;
    }

    public getVehicle(vehicleId: number): Promise<Vehicle | undefined> {
        if (vehicleId === null || vehicleId === undefined) {
            return ChargingsTable.EMPTY
        }
        if (!this.vehicles[vehicleId]) {
            this.vehicles[vehicleId] = this.vehicleService.getPromise(vehicleId, false);
        }
        return this.vehicles[vehicleId];
    }

    selectRow(row: ChargingSession) {
        if (this.selectedIndex === row.tstStart) {
            this.selectedIndex = -1;
        } else {
            this.selectedIndex = row.tstStart;
        }
    }

    public getEvse(evseId: number): Promise<Evse | undefined> {
        if (evseId === null || evseId === undefined) {
            return ChargingsTable.EMPTY
        }
        if (!this.evses[evseId]) {
            this.evses[evseId] = this.evseService.getPromise(evseId, false);
        }
        return this.evses[evseId];
    }

    public async downloadCsv() {
        if (!this.chargingProcesses || this.chargingProcesses.length === 0) {
            this.toastrService.warning("Keine Daten")
            return;
        }

        this.toastrService.info("Daten werden vorbereitet");

        const vehicles = await this.vehicleService.getAllPromise(false);
        const evses = await this.evseService.getAllPromise(false);

        const header =
            "Start;" +
            "Start (raw);" +
            "Ende;" +
            "Ende (raw);" +
            "Dauer;" +
            "Dauer (Millisekunden);" +
            "Fahrzeug;" +
            "Fahrzeug ID;" +
            "Ladepunkt;" +
            "Ladepunkt ID;" +
            "RFID;" +
            "Energie (Kilowattstunden);" +
            "Energie ca. (Kilowattstunden);" +
            "Preis (Euro);" +
            "Kostenstelle;" +
            "Flotte;" +
            "Flotte Id;" +
            "Standort;" +
            "Standort Id";

        const lines = this.chargingProcesses.map(p => {
            const vehicle = vehicles.find(v => v.id === p.vehicleId);
            const evse = evses.find(e => e.id === p.evseId);
            return new Date(p.tstStart) + ";" +
                p.tstStart + ";" +
                new Date(p.tstEnd || 0) + ";" +
                (p.tstEnd || 0) + ";" +
                (p.tstEnd ? this.durationPipe.transform(p.tstEnd - p.tstStart) : "--") + ";" +
                (p.tstEnd ? (p.tstEnd - p.tstStart) : "--") + ";" +
                (vehicle?.name || "") + ";" +
                (p.vehicleId || "") + ";" +
                (evse?.name || "") + ";" +
                (p.evseId || "") + ";" +
                (p.rfid || "") + ";" +
                (p.energyStart !== undefined ? p.energyEnd ? (p.energyEnd - p.energyStart) / 1000 : "" : "") + ";" +
                (p.energyMath ? p.energyMath / 1000 : "") + ";" +
                (p.price ? p.price.toFixed(2) : "") + ";" +
                (p.kostenstelle || "") + ";" +
                (vehicle?.fleet.name || "") + ";" +
                (vehicle?.fleet.id || "") + ";" +
                (evse?.basis.name || "") + ";" +
                (evse?.basis.id || "");
            }
        )

        const file = header + "\n" + lines.join("\n");

        download("chargings.csv", file);
    }

    public details(cs: ChargingSession): void {
        console.log(cs);


        // Otherwise the Details page would constantly send requests if vehicle/evse deleted
        if (cs.vehicleId != undefined && this.vehicles[cs.vehicleId] === undefined) {
            cs.vehicleId = undefined;
        }
        this.getEvse(cs.evseId); //This internally fills "evses" Array
        if (this.evses[cs.evseId] === undefined) {
            cs.evseId = -1;
        }
        console.log(cs);

        this.dialog.open(DetailsDialogComponent, {
            width: '80%',
            data: cs,
        });
    }

    public async downloadMeta(cs: ChargingSession): Promise<void> {
        this.downloads[cs.id] = true;
        const meta = await this.chargingService.getDetails(cs.id);
        delete this.downloads[cs.id];
        download("Details_" + cs.id + ".txt", meta.details);
    }

    public async stop(cs: ChargingSession): Promise<void> {
        const evseId = cs.evseId;
        try {
            const response = await this.evseService.stopCharging(evseId);
            if(response.success) {
                this.toastrService.info("Ladevorgang wird gestoppt");
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim stoppen des Ladevorgangs");
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim stoppen des Ladevorgangs");
            console.error(exc);
        }
        setTimeout(() => this.refresh(), 500)
    }

    public isStopped(cs: ChargingSession) {
        return cs.state === ChargingSession.StateEnum.Stopped;
    }

    public async continue(cs: ChargingSession) {
        const evseId = cs.evseId;
        try {
            const response = await this.evseService.continueCharging(evseId);
            if(response.success) {
                this.toastrService.info("Ladevorgang wird fortgesetzt.");
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs");
            console.error(exc);
        }
        setTimeout(() => this.refresh(), 500)
    }
}
